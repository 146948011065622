
import React, { useEffect, useRef } from "react";
import styles from "./ImageMarquee.module.css";

const ImageMarquee = ({ images }) => {
  
  return (
    <div className={styles.marquee_container}>
      <div className={styles.marquee}>
        {images.map((image, index) => (
          <img key={index} src={image} alt={image.alt} className={styles.image} />
        ))}
         {images.map((image, index) => (
          <img key={`duplicate-${index}`} src={image} alt={image.alt || 'Image'} className={styles.image} />
        ))}
       
      </div>
      
    </div>
  );
};

export default ImageMarquee;
