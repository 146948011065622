import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import "./CustomScrollbar.css";

const CustomScrollbar = ({ children, height = "400px", thumbSize = "44px", trackWidth = "4px" }) => {
  const contentRef = useRef();
  const scrollbarRef = useRef();
  const [isDragging, setIsDragging] = useState(false);
  const [thumbPosition, setThumbPosition] = useState(0);

  const handleMouseMove = (e) => {
    if (!isDragging) return;

    const { top, height: trackHeight } = scrollbarRef.current.getBoundingClientRect();
    let y = e.clientY - top; // Calculate relative Y position
    y = Math.max(0, Math.min(y, trackHeight - parseInt(thumbSize, 10))); // Clamp the Y position

    const scrollableHeight = contentRef.current.scrollHeight - contentRef.current.clientHeight;
    const scrollPosition = (y / (trackHeight - parseInt(thumbSize, 10))) * scrollableHeight;

    contentRef.current.scrollTop = scrollPosition;
    setThumbPosition(y);
  };

  const handleMouseDown = () => {
    setIsDragging(true);
    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleMouseUp);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    window.removeEventListener("mousemove", handleMouseMove);
    window.removeEventListener("mouseup", handleMouseUp);
  };

  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = contentRef.current;
    const trackHeight = scrollbarRef.current.clientHeight - parseInt(thumbSize, 10);

    const thumbPos = (scrollTop / (scrollHeight - clientHeight)) * trackHeight;
    setThumbPosition(thumbPos);
  };

  return (
    <div className="scroll-container" style={{ height }}>
      {/* Content to scroll */}
      <div className="content" ref={contentRef} onScroll={handleScroll}>
        {children}
      </div>

      {/* Custom Scrollbar */}
      <div className="scrollbar" ref={scrollbarRef}>
        <svg xmlns="http://www.w3.org/2000/svg" width={trackWidth} height="100%" fill="none">
          <path d={`M${parseInt(trackWidth, 10) / 2} 0L${parseInt(trackWidth, 10) / 2} 419`} stroke="url(#paint0_linear)" strokeWidth={trackWidth} />
          <defs>
            <linearGradient id="paint0_linear" x1="2.5" y1="0" x2="2.5" y2="419" gradientUnits="userSpaceOnUse">
              <stop stopColor="#E27DDD" stopOpacity="0" />
              <stop offset="0.49" stopColor="#E27DDD" stopOpacity="0.32" />
              <stop offset="0.98" stopColor="#E27DDD" stopOpacity="0" />
            </linearGradient>
          </defs>
        </svg>

        {/* Draggable Thumb */}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={thumbSize}
          height={thumbSize}
          viewBox="0 0 44 44"
          className="scroll-thumb"
          style={{ top: `${thumbPosition}px` }}
          onMouseDown={handleMouseDown}
        >
          <circle cx="22" cy="22" r="22" fill="url(#paint0_radial)" />
          <defs>
            <radialGradient
              id="paint0_radial"
              cx="0"
              cy="0"
              r="1"
              gradientUnits="userSpaceOnUse"
              gradientTransform="translate(22 22) rotate(90) scale(14.1429)"
            >
              <stop stopColor="#E27DDD" />
              <stop offset="1" stopColor="#E27DDD" stopOpacity="0" />
            </radialGradient>
          </defs>
        </svg>
      </div>
    </div>
  );
};

CustomScrollbar.propTypes = {
  children: PropTypes.node.isRequired,
  height: PropTypes.string,
  thumbSize: PropTypes.string,
  trackWidth: PropTypes.string,
};

export default CustomScrollbar;
