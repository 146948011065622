import React from "react";
import { useState } from "react";
import signUpImg from '../../Assets/Images/SignUp/signupImg.svg'
import styles from "./partnersignup.module.css";

const PartnerSignup = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    companyName: "",
    message: "",
  });
  const [responseMessage, setResponseMessage] = useState(''); // Holds the success or error message
  const [showMessage, setShowMessage] = useState(false); // Controls visibility of the message box
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const validateForm = () => {
    const newErrors = {};

    if (!formData.firstName.trim()) {
      newErrors.firstName = "First name is required";
    }

    if (!formData.email.trim()) {
      newErrors.email = "Company email is required";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      newErrors.email = "Invalid email format";
    }

    return newErrors;
  };

  const handleChange = (e) => {
    const { id, value } = e.target;

    setFormData((prevData) => ({ ...prevData, [id]: value }));

    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };

      if (id === "email" && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
        delete newErrors.email;
      } else if (value.trim()) {
        delete newErrors[id];
      }

      return newErrors;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = validateForm();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    setIsSubmitting(true);

    const apiBody = {
      first_name: formData.firstName,
      last_name: formData.lastName,
      company_email: [formData.email],
      company_name: formData.companyName,
      message: formData.message,
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}api/partner-request `, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(apiBody),
      });

      if (response.ok) {
        setResponseMessage('Message sent successfully!');
        setShowMessage(true);
        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          companyName: "",
          message: "",
        });
      } else {
        setResponseMessage('Failed to send the message. Please try again.');
        setShowMessage(true);
      }
    } catch (error) {
      setResponseMessage('An error occurred while sending the message.');
      setShowMessage(true);
    } finally {
      setIsSubmitting(false);
    }
  };
  return (
    <div className={styles.Signupcontainer}>
      <h1 className={styles.title}>Become a Partner</h1>
      <div className={styles.formContainer}>
        <div className={styles.signupformMain}>
        <form className={styles.signUpform} onSubmit={handleSubmit} >
          <div className={styles.inputRow}>
            <div className={styles.inputGroup}>
              <label htmlFor="firstName" className={styles.label}>
              <span className={styles.required}>*</span>&nbsp;First Name
              </label>
              <input
                type="text"
                id="firstName"
              
                className={`${styles.Signupinput} ${errors.firstName ? styles.inputError : ""
                }`}
                onChange={handleChange}
                value={formData.firstName}
                placeholder="Enter your first name"
              />
              {/* {errors.firstName && <span className={styles.error}>{errors.firstName}</span>}  */}
            </div>
            <div className={styles.inputGroup}>
              <label htmlFor="lastName" className={styles.label}>
                Last name
              </label>
              <input
                type="text"
                id="lastName"
                className={styles.Signupinput}
                placeholder="Enter your last name"
                value={formData.lastName}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className={styles.inputRow}>
            <div className={styles.inputGroup}>
              <label htmlFor="companyName" className={styles.label}>
                Company name
              </label>
              <input
                type="text"
                id="companyName"
                className={styles.Signupinput}
                placeholder="Where do you work?"
                value={formData.companyName}
                onChange={handleChange}
              />
            </div>
            <div className={styles.inputGroup}>
              <label htmlFor="email" className={styles.label}>
              <span className={styles.required}>*</span>&nbsp;Company Email
              </label>
              <input
                type="email"
                id="email"
                className={`${styles.Signupinput} ${errors.email ? styles.inputError : ""
                }`}
              placeholder="yourname@company.com"
              value={formData.email}
              onChange={handleChange}
              />
            </div>
          </div>
          <div className={styles.inputGroup}>
            <label htmlFor="message" className={styles.label}>
              Message
            </label>
            <textarea
              id="message"
              className={styles.textarea}
              value={formData.message}
              onChange={handleChange}
            />
          </div>
          <div className={styles.buttonRow}>
            {/* <button type="button" className={styles.loginButton}>
              Login
            </button> */}
           <button
              type="submit"
              className={styles.SignupButton}
              disabled={isSubmitting}
            >
              {isSubmitting ? "Sending..." : "Send Now"}
            </button>
          </div>
        </form>
        {showMessage && (
              <div className={styles.responseMessage}>
                <span>{responseMessage}</span>
                <button className={styles.closeButton} onClick={() => setShowMessage(false)}>X</button>
              </div>
            )}
        </div>
        
        <div className={styles.partnerimageContainer}>
          <img
            src={signUpImg}// Replace with the actual image path
            alt="Partner illustration"
            className={styles.image}
          />
        </div>
      </div>
      
    </div>
  );
};

export default PartnerSignup;
