import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./automationlibrary.module.css";
import { fetchCardData, fetchDropdownData } from "../../apiServices/AutomationLibrary/apiCalls";

const AutomationLibraryPage = () => {

  const navigate = useNavigate();
  const [dropdownOptions, setDropdownOptions] = useState({ array1: [], array2: [] });
  const [searchText, setSearchText] = useState("");
  const [selectedDropdown1, setSelectedDropdown1] = useState("");
  const [selectedDropdown2, setSelectedDropdown2] = useState("");
  const [cardsData, setCardsData] = useState([]);
  const [hasNext, setHasNext] = useState(0); // Tracks if more pages are available
  const [currentPage, setCurrentPage] = useState(1); // Tracks the current page
  console.log("cards---------", cardsData)
  // Fetch initial dropdown data and card data
  useEffect(() => {
    async function fetchData() {
      const dropdownData = await fetchDropdownData('api/filter-list');
      setDropdownOptions({
        array1: dropdownData.erp_list || [],
        array2: dropdownData.enterprise_list || [],
      });

      const response = await fetchCardData(selectedDropdown1, selectedDropdown2, 1);
      setCardsData(response?.automation_scenario_list || []);
      setHasNext(response?.has_next || 0);
    }

    fetchData();
  }, []);

  // Fetch cards whenever dropdown values change
  useEffect(() => {
    async function fetchCards() {
      const response = await fetchCardData(selectedDropdown1, selectedDropdown2, 1, searchText);
      setCardsData(response?.automation_scenario_list || []);
      setHasNext(response?.has_next || 0);
      setCurrentPage(1); // Reset to page 1 on filter change
    }

    fetchCards();
  }, [selectedDropdown1, selectedDropdown2, searchText]);

  // Load more cards
  const loadMoreCards = async () => {
    const nextPage = currentPage + 1;
    const response = await fetchCardData(selectedDropdown1, selectedDropdown2, nextPage, searchText);
    setCardsData((prevCards) => [
      ...prevCards,
      ...(response?.automation_scenario_list || []),
    ]);
    setHasNext(response?.has_next || 0);
    setCurrentPage(nextPage);
  };
  const handleCardClick = (slug1,slug2) => {
    navigate(`/automation-library/${slug1}/${slug2}`);
  };

  return (
    <div className={styles.container}>
      <div>
      <svg  className={styles.rightCurve} xmlns="http://www.w3.org/2000/svg" width="250" height="1211" viewBox="0 0 250 1211" fill="none">
  <path d="M250 1211H0C0 682.29 101.648 224.247 250 0V1211Z" fill="url(#paint0_linear_1_14499)" fill-opacity="0.35"/>
  <defs>
    <linearGradient id="paint0_linear_1_14499" x1="125" y1="1211" x2="125" y2="0" gradientUnits="userSpaceOnUse">
      <stop offset="0.0515734" stop-color="white"/>
      <stop offset="1" stop-color="#E27DDD"/>
    </linearGradient>
  </defs>
</svg>
<svg className={styles.leftCurve} xmlns="http://www.w3.org/2000/svg" width="250" height="1211" viewBox="0 0 250 1211" fill="none">
  <path d="M0 1211H250C250 682.29 148.352 224.247 0 0V1211Z" fill="url(#paint0_linear_1_14497)" fill-opacity="0.35"/>
  <defs>
    <linearGradient id="paint0_linear_1_14497" x1="125" y1="0" x2="125" y2="1211" gradientUnits="userSpaceOnUse">
      <stop stop-color="#A8BEDC"/>
      <stop offset="0.946622" stop-color="white"/>
    </linearGradient>
  </defs>
</svg>
      <h1 className={styles.heading}>Connect with <span className='rmb_run'>RUN</span><span className='rmb_my'>MY</span><span className='rmb_bot'>BOT</span> and see
        powerful results</h1>
      <div className={styles.filters}>
        <select className={styles.dropdown}
          value={selectedDropdown1}
          onChange={(e) => setSelectedDropdown1(e.target.value)}
        >
          <option value="">By Function</option>
          {dropdownOptions.array1.map((item, index) => (
            <option key={index} value={item.id}>
              {item.title}
            </option>
          ))}
        </select>
        <select className={styles.dropdown}
          value={selectedDropdown2}
          onChange={(e) => setSelectedDropdown2(e.target.value)}
        >
          <option value="">By Application</option>
          {dropdownOptions.array2.map((item, index) => (
            <option key={index} value={item.slug}>
              {item.title}
            </option>
          ))}
        </select>
        <input
          type="text"
          className={styles.search}
          placeholder="Search..."
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
      </div>


      {cardsData?.length > 0 ? (

        <div className={styles.cardGrid}>
          {cardsData.map((card, index) => (
            <div key={index} className={styles.libraryCard} onClick={() => handleCardClick(card.application__slug, card.slug)}>
              <div className={styles.image_container}>
                <img
                  src={`${process.env.REACT_APP_API_BASE_URL}attachments/${card.icon}`}
                  alt={card.label}
                  className={styles.cardImage}
                />
              </div>
              <div className={styles.cardLabel}>{card.short_description}</div>
            </div>
          ))}
        </div>

      ) : (
        <div className={styles.noDataFound}>No data found.</div>
      )}

      {hasNext === 1 && (
        <div className={styles.loadMore}>
          <button onClick={loadMoreCards}>Load More</button>
        </div>

      )}

    </div>
      </div>
      
  );
};

export default AutomationLibraryPage;
