import React from 'react'
import { useNavigate } from 'react-router-dom'
import aiheaderimg from '../../Assets/Images/AIanalyze/aianalyze.svg'
import ai_iot from '../../Assets/Images/AIanalyze/ai_IOT.svg'
import cloudmanagement from '../../Assets/Images/AIanalyze/cloudmanagementAI.svg'
import eventstream from '../../Assets/Images/AIanalyze/eventstreamAI.svg'
import styles from './aianalyzepage.module.css'


const featuresData = [
       
  {
  //   heading: "More than just integrations ",
    label: "IoT Data collector",
    description: "<p><span class='rmb_run_p'>RUN</span><span class='rmb_my_p'>MY</span><span class='rmb_bot_p'>BOT®</span> has built-in data collectors for different IoT devices, including store-and-forward types and low-power protocols. The collector algorithms are designed to understand data drift and automatic learning to address quality issues.</p>",
    image: ai_iot
  },
  {
   
    label: "Event Stream Processing",
    description: "<p>Event Stream Processing <span class='rmb_run_p'>RUN</span><span class='rmb_my_p'>MY</span><span class='rmb_bot_p'>BOT®</span> is designed to support varying data volumes and ingestion rates. <span class='rmb_run_p'>RUN</span><span class='rmb_my_p'>MY</span><span class='rmb_bot_p'>BOT®</span> can parse, enrich and apply complex transformations to create data pipelines that can be easily consumed by business applications.</p>",
    image: eventstream
  },
  {
   
    label: "Edge to Cloud Management",
    description: "<p>Our AI-driven platform can detect anomalous behaviors and react timely to operational events. This fully managed platform and its analytics capabilities address the lineage and governance of streaming data from end to end.</p>",
    image: cloudmanagement
  }
  
];

const AianalyzePage = () => {

  const navigate=useNavigate();
   const handleDemoClick =()=>{
    navigate('/scheduledemo')
   }

  return (
    <div className={styles.analyzeContainer}>
            <div className={styles.headerImg}>
            <img src={aiheaderimg} alt='image'/>
            </div>
          <div className={styles.analyzeHeaderContainer}>
        
              
              <h1 >AI-Powered Stream Processing to Manage Your IoT Data Needs</h1>

              <p><span className='rmb_run_p'>RUN</span><span className='rmb_my_p'>MY</span><span className='rmb_bot_p'>BOT®</span> For SAP is a Hyperautomation-as-a-Service solution built on SAP BTP to help achieve your hyperautomation goals without any back-end customizations in SAP. Now achieve your hyperautomation goals without writing any code.</p>
              <div className={styles.explorefurther}>
                  <button onClick={handleDemoClick}>Schedule a demo</button>
              </div>
          </div>

          {/* Features Section */}
      
        <div className={styles.featuresContainer}>
      {featuresData.map((feature, index) => (
        <div
          key={index}
          className={`${styles.featureSection} ${
            index % 2 === 0 ? styles.rightImage : styles.leftImage
          }`}
        >
          <div className={styles.textContent}>
            <div className={styles.featureLabel}>{feature.label}</div>
            <div
                    dangerouslySetInnerHTML={{ __html: feature.description }}
                    className={styles.featureDescription}
                   
                  />
            {/* <p className={styles.featureDescription}>{feature.description}</p> */}
          </div>
          <div className={styles.imageContainer}>
            <img src={feature.image} alt={feature.label} className={styles.featureImage} />
          </div>
        </div>
      ))}
         </div>
       
          


    </div>
  )
}

export default AianalyzePage