import React from 'react'
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import CounterDisplay from '../../components/Layout/counter/CounterDisplay'
import partnerImg from '../../Assets/Images/partners/partnerImg.svg'
import styles from './partnerPage.module.css'
import MapComponent from '../../components/Maps/MapComponent'
import partners_bussiness from '../../Assets/Images/partners/partners_bussiness.svg'
import { fetchEndusers } from '../../apiServices/EndUsers/apiCalls'
import ImageCarousel from '../../components/AutomationRunsAt/ImageCarousel'
const PartnerPage = () => {

  const [partnerLocations, setPartnerLocations] = useState([]);
  const navigate = useNavigate();
  const handleDemoClick = () => {
    navigate('/scheduledemo')
  }
  const handleSignUpClick = () => {
    navigate('/partner/Signup')
  }
  // Example data: Replace this with your API data
  const locations = [
    { name: 'Company A', lat: 37.7749, lng: -122.4194, description: 'San Francisco' },
    { name: 'Company B', lat: 51.5074, lng: -0.1278, description: 'London' },
    { name: 'Company C', lat: 35.6895, lng: 139.6917, description: 'Tokyo' },
  ];
  const [imageData, setImagedata] = useState([])

  useEffect(() => {
    // Fetch image data from API
    const fetchImages = async () => {
      try {
        const result = await fetchEndusers('api/end-users'); // Adjust endpoint
        // Extract logo URLs from API response
        const images = result.end_users.map((user) => `${process.env.REACT_APP_API_BASE_URL}attachments/${user.logo}`);
        setImagedata(images)
      } catch (err) {

      }
    };

    fetchImages();
  }, []);
  useEffect(() => {
    const interval = setInterval(() => {
      if (partnerLocations.length < locations.length) {
        setPartnerLocations((prev) => [...prev, locations[prev.length]]);
      } else {
        clearInterval(interval);
      }
    }, 1000); // Add a new point every second
    return () => clearInterval(interval);
  }, [partnerLocations]);

  return (
    <div className={styles.partnerContainer}>
      <div className={styles.partnerSection1}>
        <div className={styles.textSection}>
          <h1>Become a Partner</h1>
          <p>
            Managing workflows across multiple applications and platforms has never been easier. Our event-driven Advanced Scheduler addresses key challenges in business process automation and management with innovative solutions that streamline integration, enhance flexibility, and drive efficiency.
          </p>
          <button className={styles.ExpRmbButton} onClick={handleDemoClick}>Schedule a demo </button>
        </div>
        <div className={styles.imageSection}>
          <img src={partnerImg} alt="Sample" className={styles.image} />
        </div>

      </div>
      <div className={styles.partnerSection2}>
        <CounterDisplay />
      </div>
      <div className={styles.ourPartnersSection3}>
        <span >Our Partners</span>

        <p>Managing workflows across multiple applications and platforms has never been easier. </p>
        <MapComponent partnerLocations={partnerLocations} />

      </div>
      <div className={styles.ourPartnersSection4}>
        <span >Trusted by partners. Recognized by the industry.</span>

        <p>We owe it to our current partners. They help us learn and innovate. We are here because of them. Each day, our customers depend on ActionFi to handle millions of integration transactions that keep their businesses running smoothly. Our customers' trust, combined with our innovative products, has positioned us as a leader in both integrations and automations. </p>
        <img src={partners_bussiness} alt="Sample" className={styles.image} />
      </div>
      <div className={styles.ourPartnersSection5}>
        <div className={styles.card}>
          <div className={styles.cardContent}>
            <div className={styles.cardLabel}>Become a partner</div>
            <p className={styles.cardDescription}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
            <button className={styles.cardButton} onClick={handleSignUpClick}>Sign up</button>
          </div>
          {/* <div className={styles.cardContent}>
            <div className={styles.cardLabel}>Login with partner</div>
            <p className={styles.cardDescription}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
            {/* <button className={styles.cardButton}>Login</button> */}
          {/* </div> */}
        </div>

      </div>
      <div className={styles.ourPartnersSection6}>
        <span>Our automations run at these exceptional companies</span>
        <div style={{ display: "flex", margin: "auto", justifyContent: "center", marginTop: "64px", marginBottom: "152px" }}>
          <ImageCarousel images={imageData} showdiv={true} />
        </div>

      </div>

    </div>
  )
}

export default PartnerPage