// ScrollContext.js
import React, { createContext, useContext, useRef } from 'react';

// Create a context for scroll functionality
const ScrollContext = createContext();

// Create a custom hook to use the scroll context
export const useScroll = () => useContext(ScrollContext);

// Create the ScrollProvider component
export const ScrollProvider = ({ children }) => {
  const sectionRefs = useRef({});

  // Function to scroll to a section by its ID
  const scrollToSection = (sectionId) => {
    const section = sectionRefs.current[sectionId];
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <ScrollContext.Provider value={{ scrollToSection, sectionRefs }}>
      {children}
    </ScrollContext.Provider>
  );
};
