/*
purpose of this: Solutions of website
Author: Jinto Jose
Completed date: 
*/

import React from 'react'
import { useNavigate } from 'react-router-dom'
import styles from '../morethanaplatform/MoreThanPlatform.css'
import connect from'../../Assets/Images/morethanaplatform/connect.svg'
import datawrangler from'../../Assets/Images/morethanaplatform/datawrangler.svg'
import customize from'../../Assets/Images/morethanaplatform/customize.svg'
import analyze from'../../Assets/Images/morethanaplatform/analyze.svg'
import solutionmain from'../../Assets/Images/solutions/solutionMain.svg'

const solutionsData = [
  {
    label: "Connect",
    content: "Extract/Transform/Load",
    image: connect,
    path:"/connect"
  },
  {
    label: "Data Wrangler",
    content: "Data prep/Blend/ Enhancement",
    image: datawrangler,
    path:"/dataWrangler"
  },
  {
    label: "Customize",
    content: "Create workflow/Customize Integrations",
    image: customize,
    path:"/workflows"
  },
  {
    label: "Analyze",
    content: "AI/Insight/Reporting",
    image: analyze,
    path:"/aiAnalyze"
  },
];

const MoreThanPlatform = () => {
  const navigate=useNavigate()
  const handleClick=(path)=>{
    navigate(path)

  }
 

  return (
    <div className="solutionsContainer">
      {solutionsData.map((solution, index) => (
        <div key={index} className="solutionBox" onClick={()=>{handleClick(solution.path)}}>
          <img src={solution.image} alt={solution.label} />
          <div className="textdata" >
            <h3>{solution.label}</h3>
            <p>{solution.content}</p>
            
          </div>
          {/* <div className="learnMoreText">Learn More</div> */}
        </div>
      ))}
    </div>
  )
}

export default MoreThanPlatform