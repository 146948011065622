import React from 'react'
import { useState, useEffect } from 'react'
import aiheaderimg from '../../Assets/Images/workflowbuilder/workflowbuilder.svg'
import styles from './endorPage.module.css'
import ImageMarquee from '../../components/ImageMarquee/ImageMarquee'
import endor_feature from '../../Assets/Images/endor_feature.svg'
import { fetchEndusers } from '../../apiServices/EndUsers/apiCalls'


const featuresData = [

  {
    //   heading: "More than just integrations ",
    label: "Fully Managed Hyperautomation-as-a-Service (HaaS) Platform Built on SAP BTP",
    description: "Take a different approach to automating Digital Workflows in the modern world that span regions, time zones, departments, systems, technologies and productivity tools with on-premise and on-the-cloud tasks. Achieve faster integrations with functional-specific SAP integrations out of the box, with structured and unstructured data support and AI/ML capabilities.",
    image: endor_feature
  },


];

const EndorPage = () => {
  const [imageData, setImagedata] = useState([])

  useEffect(() => {
    // Fetch image data from API
    const fetchImages = async () => {
      try {
        const result = await fetchEndusers('api/end-users'); // Adjust endpoint
        // Extract logo URLs from API response
        const images = result.end_users.map((user) => `${process.env.REACT_APP_API_BASE_URL}attachments/${user.logo}`);
        setImagedata(images)
      } catch (err) {

      }
    };

    fetchImages();
  }, []);
  return (
    <div className={styles.endorMainconatiner}>
      <div className={styles.contentContainer}>
        <div className={styles.headerimageworkflow}>
          <svg  className={styles.headerimgleft} width="168" height="168" viewBox="0 0 168 168" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="-34" y="83.9731" width="166.839" height="166.839" rx="83.4196" transform="rotate(-45 -34 83.9731)" fill="url(#paint0_linear_1_4881)" />
            <defs>
              <linearGradient id="paint0_linear_1_4881" x1="49.4196" y1="83.9731" x2="49.4196" y2="250.812" gradientUnits="userSpaceOnUse">
                <stop stop-color="#F4D7F5" />
                <stop offset="1" stop-color="#E27DDD" />
              </linearGradient>
            </defs>
          </svg>
          <svg  className={styles.headerimgright} width="168" height="168" viewBox="0 0 168 168" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="-34" y="83.9731" width="166.839" height="166.839" rx="83.4196" transform="rotate(-45 -34 83.9731)" fill="url(#paint0_linear_1_4886)" />
            <defs>
              <linearGradient id="paint0_linear_1_4886" x1="49.4196" y1="83.9731" x2="49.4196" y2="250.812" gradientUnits="userSpaceOnUse">
                <stop stop-color="#7398C7" />
                <stop offset="1" stop-color="#C8D7EA" />
              </linearGradient>
            </defs>
          </svg>

          <img
            src={aiheaderimg}
            alt="Connect Illustration"
            className={styles.image}
          />
        </div>

        <h1 className={styles.heading}>Workflow Builder</h1>
        <p className={styles.description}>
          <span class='rmb_run_p'>RUN</span><span class='rmb_my_p'>MY</span><span class='rmb_bot_p'>BOT®</span> For SAP is a Hyperautomation-as-a-Service solution built on SAP BTP to help achieve your hyperautomation goals without any back-end customizations in SAP. Now achieve your hyperautomation goals without writing any code.            </p>

      </div>

      <div className={styles.rmbsapSection5} style={{ maxWidth: "100%" }}>
        <ImageMarquee images={imageData} speed={20} />
      </div>

      <div className={styles.featuresContainerWorkflow}>
        {featuresData.map((feature, index) => (
          <div
            key={index}
            className={`${styles.featureSection} ${index % 2 === 0 ? styles.rightImage : styles.leftImage
              }`}
          >
            <div className={styles.textContent}>
              <div className={styles.featureLabel}>{feature.label}</div>
              <p className={styles.featureDescription}>{feature.description}</p>
            </div>
            <div className={styles.imageContainer}>
              <img src={feature.image} alt={feature.label} className={styles.featureImage} />
            </div>
          </div>
        ))}
      </div>


    </div>
  )
}

export default EndorPage