import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./IntegrationCard.module.css";

const IntegrationCards = ({ integrationData }) => {
  const cardsPerPage = 8;
  const transitionDuration = 1000; // Transition duration for sliding
  const intervalDuration = 5000; // Interval for auto-sliding

  const totalSets = Math.ceil(integrationData?.length / cardsPerPage);
  const [currentSet, setCurrentSet] = useState(0);
  const [isSliding, setIsSliding] = useState(false);
  const [displayedData, setDisplayedData] = useState(integrationData.slice(0, cardsPerPage));
  const [nextData, setNextData] = useState(integrationData.slice(cardsPerPage, cardsPerPage * 2));

  const navigate = useNavigate();
  let slideInterval;

  // Handle sliding to the next set
  const slideNext = () => {
    if (!isSliding) {
      setIsSliding(true);

      setTimeout(() => {
        const nextSet = (currentSet + 1) % totalSets;
        const newDisplayedData = integrationData.slice(nextSet * cardsPerPage, nextSet * cardsPerPage + cardsPerPage);
        const newNextSet = (nextSet + 1) % totalSets;
        const newNextData = integrationData.slice(newNextSet * cardsPerPage, newNextSet * cardsPerPage + cardsPerPage);

        setDisplayedData(newDisplayedData);
        setNextData(newNextData);
        setCurrentSet(nextSet);
        setIsSliding(false);
      }, transitionDuration);
    }
  };

  // Start the slider
  const startSlider = () => {
    slideInterval = setInterval(slideNext, intervalDuration);
  };

  // Stop the slider
  const stopSlider = () => {
    clearInterval(slideInterval);
  };

  // Automatically transition every `intervalDuration`
  useEffect(() => {
    startSlider();
    return () => stopSlider(); // Cleanup interval on unmount
  }, [currentSet]);

  // Handle Learn More button click
  const handleLearnMore = (id) => {
    navigate(`/integration/${id}`); // Navigate to a new page based on the ID
  };

  return (
    <div className={styles.integrationContainer}>
      <h2 className={styles.heading}>Featured Integrations</h2>
      <div
        className={styles.sliderWrapper}
        onMouseEnter={stopSlider}
        onMouseLeave={startSlider}
      >
        <div
          className={`${styles.grid} ${isSliding ? styles.sliding : ""}`}
          key={currentSet}
        >
          {displayedData.map((card, index) => (
            <div key={`current-${index}`} className={styles.card}>
        
              <div className={styles.cardImage}>
                <img
                  src={`${process.env.REACT_APP_API_BASE_URL}attachments/${card.logo}`}
                  alt={card.title}
                />
              </div>
              <p>{card.title}</p>
              <div className={styles.learnmorecontainer}  onClick={() => handleLearnMore(card.id)} >
                <button
                  className={styles.learnmoreBtn}
                 // Pass the card ID
                >
                  Learn More
                </button>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 21.9248V21.9248C7.029 21.9248 3 17.8958 3 12.9248V12.9248C3 7.9538 7.029 3.9248 12 3.9248V3.9248C16.971 3.9248 21 7.9538 21 12.9248V12.9248C21 17.8958 16.971 21.9248 12 21.9248Z"
                    stroke="black"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M16 12.9248H8"
                    stroke="black"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M13 9.9248L16 12.9248L13 15.9248"
                    stroke="black"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>
          ))}
        </div>

        {/* {isSliding && (
          <div className={`${styles.grid} ${styles.nextSlide}`}>
            {nextData.map((card, index) => (
              <div className={styles.box}>
                <div key={`next-${index}`} className={styles.card}>

                  <div className={styles.cardImage}>
                    <img
                      src={`${process.env.REACT_APP_API_BASE_URL}attachments/${card.logo}`}
                      alt={card.title}
                    />
                  </div>
                  <p>{card.title}</p>
                  <div className={styles.learnmorecontainer} onClick={() => handleLearnMore(card.id)}>
                    <button
                      className={styles.learnmoreBtn}

                    >
                      Learn More
                    </button>
                  </div>
                </div>
              </div>

            ))}
          </div>
        )} */}
      </div>

      <div className={styles.dots}>
        {Array.from({ length: totalSets }).map((_, index) => (
          <div
            key={index}
            className={`${styles.dot} ${currentSet === index ? styles.active : ""}`}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default IntegrationCards;