import React from 'react'
import { useState,useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import companyHeader from '../../Assets/Images/OurCompany/Company.svg'
import company2 from '../../Assets/Images/OurCompany/company2.svg'
import company3 from '../../Assets/Images/OurCompany/company3.svg'
import company4 from '../../Assets/Images/OurCompany/company4.svg'
import company5 from '../../Assets/Images/OurCompany/company5.svg'
import company6 from '../../Assets/Images/OurCompany/company6.svg'
import styles from './companyPage.module.css'
import Awards from '../../components/Awards/Awards'
import { fetchEndusers } from '../../apiServices/EndUsers/apiCalls'
import ImageCarousel from '../../components/AutomationRunsAt/ImageCarousel'
import { fetchPartners } from '../../apiServices/Partners/apiCalls'


const CompanyPage = () => {

  const navigate=useNavigate();
  const [imageData,setImagedata]=useState([])
  const [partners,setPartners]=useState([])

  useEffect(() => {
    // Fetch image data from API
    const fetchImages = async () => {
      try {
        const partnersData =await fetchPartners('api/partners')
        const result = await fetchEndusers('api/end-users'); // Adjust endpoint
        
        // Extract logo URLs from API response
        const images = result.end_users.map((user) => `${process.env.REACT_APP_API_BASE_URL}attachments/${user.logo}`);
        const partnersImages = partnersData.partners.map((user) => `${process.env.REACT_APP_API_BASE_URL}attachments/${user.logo}`);
        setImagedata(images)  
        setPartners(partnersImages)    
      } catch (err) {
   
      } 
    };

    fetchImages();
  }, []);
  const handleDemoClick =()=>{
    navigate('/scheduledemo')
   }
   const customStyle = {
    boxShadow:'none',
    backgroundColor: '#F6FAFF'
  };
  return (
    <div className={styles.companyContainer}>
      {/* Heading */}
      <h1 className={styles.companyHeading}>Elevate your Business through Seamless Automation and Integration</h1>

      {/* Image */}
      <div className={styles.imageContainerMain}>
        <img  alt="img" className={styles.companyImage}  src={companyHeader}/>
      </div>
        {/* Features Section */}
      <div className={styles.featuresContainerMain}>

        <div className={styles.featuresContainerCompany}>
          
            <div
         
              className={`${styles.featureSection} ${ styles.rightImage}`}
            >
              <div className={styles.textContent}>
                <div className={styles.featureLabel}>Virtual Automation COE</div>
                <p className={styles.featureDescription}>End-to-end integration and automation is our sole focus, and we provide it at predictable cost. With our expertise and as your organization's virtual Automation Center of excellence, actionfi helps you cross that final mile of automation.</p>
              </div>
              <div className={styles.imageContainer}>
                <img src={company2} alt={'Virtual Automation COE'} className={styles.featureImage} />
              </div>
            </div>
            <div
         
              className={`${styles.featureSection} ${ styles.leftImage 
                }`}
            >
              <div className={styles.textContent}>
                <div className={styles.featureLabel}>Full-Life-Cycle Support</div>
                <p className={styles.featureDescription}>We provide full-life-cycle support. Our integration team continues to update all our integration points, ERP versions and APIs. We also believe in providing full-life-cycle support to all our customers for our standard subscription fee.</p>
              </div>
              <div className={styles.imageContainer}>
                <img src={company3} alt='Full-Life-Cycle Support' className={styles.featureImage} />
              </div>
            </div>
            <div
              
              className={`${styles.featureSection} ${ styles.rightImage}`}
            >
              <div className={styles.textContent}>
                <div className={styles.featureLabel}>Early Error Indicator</div>
                <p className={styles.featureDescription}>We know about any issues before your users experience it. Our proactive, AI-led monitoring and pattern recognition system keeps an eye on all integration points and automation as part of our fully managed offering. Should any error or failure arise, it'll appear on our radar before your users experience it and by which time we could already be working its resolution.</p>
              </div>
              <div className={styles.imageContainer}>
                <img src={company4} alt='Early Error Indicator' className={styles.featureImage} />
              </div>
            </div>
            <div
              
              className={`${styles.featureSection} ${ styles.leftImage}`}
            >
              <div className={styles.textContent}>
                <div className={styles.featureLabel}>Valuable Insights</div>
                <p className={styles.featureDescription}><span className='rmb_run_p'>RUN</span><span className='rmb_my_p'>MY</span><span className='rmb_bot_p'>BOT®</span> insights make your automation life easier. We help customers improve their processes by providing historical data analysis on automation, correlation of events on integration points and error scenarios. We work with our customers to make a joint roadmap on integration and automation that helps deliver value to the end users. Our customers also enjoy the support of the platform with their proof of concepts and co-innovation projects.</p>
              </div>
              <div className={styles.imageContainer}>
                <img src={company5} alt='Valuable Insights' className={styles.featureImage} />
              </div>
            </div>
            <div
              
              className={`${styles.featureSection} ${ styles.rightImage
                }`}
            >
              <div className={styles.textContent}>
                <div className={styles.featureLabel}>All-in-ONE Subscription</div>
                <p className={styles.featureDescription}>One simple subscription fee covers all automation scenarios. From ideation to concept, solution, implementation, upgrades, maintenance, monitoring, analysis and support, all steps of your automation projects are covered in our fully managed automation model.</p>
              </div>
              <div className={styles.imageContainer}>
                <img src={company6} alt='Full-Life-Cycle Support' className={styles.featureImage} />
              </div>
            </div>
         
        </div>
      </div>

      <div className={styles.CompanyPagePartners}>
      <h1 >Partners</h1>
      {partners.length > 0 ? (
            <>
              <ImageCarousel images={partners} itemStyle={customStyle}  showdiv={false}/>

            </>
          ) : (
            <p>Loading...</p> // Handle cases where grid is undefined or has fewer than 4 elements
          )}
      </div>
      <div className={styles.automationRunsat}>
        <p>Our automations run at</p>

       
          {imageData.length > 0 ? (
            <>
              <ImageCarousel images={imageData} itemStyle={customStyle} showdiv={true} />

            </>
          ) : (
            <p>Loading...</p> // Handle cases where grid is undefined or has fewer than 4 elements
          )}


      
      </div>
      <Awards />
      <div className={styles.experience}>
        <h1 >Experience Our Difference</h1>

        <p>Your organization might rely on different business application, be it legacy or modern-<span className='rmb_run_p'>RUN</span><span className='rmb_my_p'>MY</span><span className='rmb_bot_p'>BOT®</span> provides a solution that will help integrate your critical business application. Save your business time and money!</p>
        <div className={styles.explorefurther}>
          <button onClick={handleDemoClick}>Schedule a Demo</button>
        </div>
      </div>
    </div>
  )
}

export default CompanyPage