export const fetchIntegrationDetails = async (id) => {
  
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}api/system-integration/${id}/`,{
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) {
        throw new Error("Failed to fetch details");
      }
      return await response.json();
    } catch (error) {
      console.error("Error in fetchCardDetails:", error);
      throw error; // Re-throw error for further handling
    }
  };