import React from 'react'
import rmbsap_center from '../../Assets/Images/RmbSap/rmbsap_center.svg'
import styles from "./headerImage.module.css";

const HeaderImage = () => {
  return (
    <>
      <div className={styles.rmb_svgContainer}>
          <svg   className={styles.svgCirclePink} width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="-3.36719" y="11.2061" width="20.6484" height="20.6484" rx="10.3242" transform="rotate(-45 -3.36719 11.2061)" fill="#EBB6EC" />
          </svg>
          <svg className={styles.svgCirclePinkSmall} width="9" height="10" viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="-1.50391" y="4.70117" width="8.67233" height="8.67233" rx="4.33616" transform="rotate(-45 -1.50391 4.70117)" fill="#E7ADE9" />
          </svg>

          <svg
            className={styles.topLeftSvg}
            width="217"
            height="216"
            viewBox="0 0 217 216"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="-9.55469"
              y="107.973"
              width="166.839"
              height="166.839"
              rx="25"
              transform="rotate(-45 -9.55469 107.973)"
              fill="url(#paint0_linear_1_1176)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_1_1176"
                x1="73.8649"
                y1="107.973"
                x2="73.8649"
                y2="274.812"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#F4D7F5" />
                <stop offset="1" stopColor="#E27DDD" />
              </linearGradient>
            </defs>
          </svg>

          <img className={styles.image} src={rmbsap_center} alt="" />

          <svg
            className={styles.bottomRightSvg}
            width="216"
            height="216"
            viewBox="0 0 216 216"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="-9.94141"
              y="107.964"
              width="166.839"
              height="166.839"
              rx="25"
              transform="rotate(-45 -9.94141 107.964)"
              fill="url(#paint0_linear_1_1181)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_1_1181"
                x1="73.4782"
                y1="107.964"
                x2="73.4782"
                y2="274.803"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#7398C7" />
                <stop offset="1" stopColor="#C8D7EA" />
              </linearGradient>
            </defs>
          </svg>
          <svg className={styles.svgcircleRight} width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="20.6484" height="20.6484" rx="10.3242" transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 25.1172 11.1738)" fill="#A8BEDC"/>
</svg>
<svg  className={styles.svgcircleRightSmall} width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="8.67233" height="8.67233" rx="4.33616" transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 11.2539 4.66895)" fill="#94AFD4"/>
</svg>


        </div>
    </>
   
  )
}

export default HeaderImage