import React from "react";
import signUpImg from '../../Assets/Images/SignUp/signupImg.svg'
import styles from "./partnerSignin.module.css";

const PartnerSignIn = () => {
  return (
    <div className={styles.Signincontainer}>
      <h1 className={styles.title}>Become a Partner</h1>
      <div className={styles.formContainer}>
        <form className={styles.signinform}>
          <div className={styles.inputRow}>
            <div className={styles.inputGroup}>
              <label htmlFor="firstName" className={styles.label}>
                Email
              </label>
              <input
                type="text"
                id="firstName"
                className={styles.SignIninput}
                placeholder="Enter Email"
              />
            </div>
            
          </div>
          <div className={styles.inputRow}>
            <div className={styles.inputGroup}>
              <label htmlFor="companyName" className={styles.label}>
               Password 
              </label>
              <input
                type="text"
                id="companyName"
                className={styles.SignIninput}
                placeholder="Enter Password"
              />
            </div>
            
          </div>
          
          <div className={styles.buttonRow}>
            <button type="button" className={styles.loginButton}>
              Login
            </button>
            <button type="submit" className={styles.signinButton}>
              Sign up
            </button>
          </div>
        </form>
        <div className={styles.partnerimageContainer}>
          <img
            src={signUpImg}// Replace with the actual image path
            alt="Partner illustration"
            className={styles.image}
          />
        </div>
      </div>
    </div>
  );
};

export default PartnerSignIn;
