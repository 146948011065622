export const fetchFunctions = async (endpoint) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}api/functions/`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      if (!response.ok) {
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }
  
      return await response.json(); // Parse JSON data
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error; // Propagate error to handle it in the component
    }
  };
  // apiService.js

export const fetchWorkflows = async (itemId) => {
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}api/workflows/?id=${itemId}`, {
      method: "GET",
    });
    if (!response.ok) {
      throw new Error("Failed to fetch from First API");
    }
    return response.json();
  };
  
  export const fetchIntegrations = async (itemId) => {
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}api/integrations/?id=${itemId}`, {
        method: "GET"
  
      
    });
    if (!response.ok) {
      throw new Error("Failed to fetch from Second API");
    }
    return response.json();
  };
  