import React from "react";
import styles from "./PlatformFeature.module.css";

const PlatformFeatureCard = ({ data }) => {
  return (
    <div className={styles.platformcardContainer}>
      {data.map((card, index) => (
        <div key={index} className={styles.platformcard} style={{ backgroundColor: card.backgroundColor }}>
          <div className={styles.platformcardheading}>
            <div className={styles.Headinglabel}>{card.label}</div>
            <div className={styles.imageContainer} style={{ backgroundColor: card.imageBackgroundColor }}>
              <img src={card.image} alt={card.label} className={styles.image} />
            </div>
          </div>
          <div className={styles.description}>
            {card.descriptions.map((desc, idx) => (
              <div key={idx} className={styles.descriptionItem}>
                 <div
                  className={styles.descriptionLabel}
                  style={{ color: desc.labelColor }} 
                >
                  {desc.label}:
                </div>
                <p className={styles.descriptionText}>{desc.text}</p>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default PlatformFeatureCard;
