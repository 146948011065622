// src/apiCalls.js

export const fetchImages = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}api/awards`); // Replace with your actual API endpoint
      const data = await response.json();
      if (data.success) {
        return data.awards.map(item => ({
          id: item.id,
          title: item.title,
          logo: item.logo, // Assuming 'icon' is the image URL
          link:item.link
        }));
      }
      return [];
    } catch (error) {
      console.error("Error fetching images:", error);
      return [];
    }
  };
  