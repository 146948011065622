import React, { useState,useEffect } from "react";
import { useNavigate, Link, NavLink } from "react-router-dom";
import styles from './navbar.module.css'; // Import styles for the navbar
import actionFiLogo from "../../../Assets/Images/actionfiLogo.png";
import CustomDropdownBar from "../../CustomNavdropdown/CustomDropdownBar";
import { fetchEnterPrizeList } from "../../../apiServices/EnterpriseList/apiCalls";
  // Dynamic data for eac
const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(""); // Tracks the active dropdown section
  const [dropdownData, setDropdownData] = useState({
    solutions: [
      {
        title: "By Integrations",
        path:"connect",
        items: [
          { label: "Sales Transaction Data Posting to NetSuite", path: "",slug:"" },
          { label: "General Ledger Balance Extraction from Oracle EBS", path: "" },
          { label: "SAP Bank Balance File Transformation", path: "" },
          { label: "Master Data Extraction from MS Dynamics 365", path: "" },
        ],
      },
      {
        title: "By Functions",
        path:"functions",
        items: [
          { label: "Retail", path: "" },
          { label: "Finance", path: "" },
          { label: "Insurance", path: "" },
          { label: "Cross-Domain", path: "" },
        ],
      },
      {
        title: "By Workflows",
        path:"workflows",
        items: [
          { label: "Customer Data Posting from POS to Oracle NetSuite", path: "" },
          { label: "Journal Entry Posting from SFTP to SAP S/4HANA", path: "" },
          { label: "Purchase Order Extraction from Oracle EBS to POS", path: "" },
          { label: "Inventory Data Posting from POS to Microsoft Azure", path: "" },
        ],
      },
      {
        title: "By Systems",
        path:"Systems",
        items: [
          { label: "Oracle NetSuite", path: "" },
          { label: "SAP S/4 HANA", path: "" },
          { label: "Microsoft Dynamics", path: "" },
          { label: "Oracle EBS", path: "" },
        ],
      },
    ],
    platform: [
   
      {
        title: "Connect",
        path:"connect",
       
      },
      {
        title: "Data Wrangler",
        path:"dataWrangler",
        
      },    
      {
        title: "Workflow Builder",
        path:"workflowBuilder",
       
      },
      {
        title: "Analyze",
        path:"aiAnalyze",
       
      },
      {
        title: "RUNMYBOT for SAP",
        path:"runmybotforsap",
        
      },
      {
        title: "Automation Library",
        path:"AutomationLibrary",
        
      },
      {
        title: "Event Pro",
        path:"runmybotEventpro",
        
      },
    ],
  })
  const navigate = useNavigate();
  useEffect(() => {
    const updateDropdownData = async () => {
      try {
        // Fetch data from the API
        const systemsData = await fetchEnterPrizeList("api/system-list");
  
        // Update the solutions array dynamically based on the title
        const updatedSolutions = dropdownData.solutions.map((solution) => {
          if (solution.title === "By Systems") {
            // If the section is "By Systems", update the path based on system id
            const updatedItems = solution.items.map((item) => {
              // Find the matching system in the API response by title
              const matchingApiItem = systemsData.systems?.find(
                (apiItem) => apiItem.title === item.label // Match by label (title)
              );
              return matchingApiItem
                ? { ...item, path: `/integration/${matchingApiItem.id}` }
                : item; // Retain original item if no match is found
            });
  
            return { ...solution, items: updatedItems };
          } else {
            // For other sections, return the solution unchanged
            return solution;
          }
        });
  
        // Update the state with the new dropdown data
        setDropdownData((prevData) => ({
          ...prevData,
          solutions: updatedSolutions,
        }));
      } catch (error) {
        console.error("Error updating dropdown data:", error);
      }
    };
  
    updateDropdownData();
  }, []);
  
  console.log("dropdwon",dropdownData)
  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev);
  };
  const handleMouseEnter = (dropdownKey) => {
    setActiveDropdown(dropdownKey); // Open the dropdown on hover
  };

  const handleMouseLeave = () => {
    setActiveDropdown(""); // Close the dropdown when the mouse leaves
  };
    // Close the menu when resizing above 1000px
    useEffect(() => {
      const handleResize = () => {
        if (window.innerWidth > 1120) {
          setIsMenuOpen(false);
        }
      };
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);
    
  return (
    <nav className={styles.navbar}>
      <div className={styles.navbar__logo}>
      <Link to="/">
          <img src={actionFiLogo} alt="Logo" className={styles.navbar__logoImg} />
        </Link>
      </div>
      <button className={styles.hamburger} onClick={toggleMenu}>
        <svg
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          stroke="#4F63BE"
        >
          <path
            d="M3 6.00092H21M3 12.0009H21M3 18.0009H21"
            stroke="#292929"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></path>
        </svg>
      </button>
      <ul  className={`${styles.navbar__links} ${
          isMenuOpen ? styles.navbar__links__visible : ""
        }`}>
             {isMenuOpen && (
          <span className={styles.close} onClick={() => setIsMenuOpen(false)}>
            X
          </span>
        )}
        <li
          className={styles.navbar__item}
          onMouseEnter={() => handleMouseEnter("solutions")}
          onMouseLeave={handleMouseLeave}
        >
          <NavLink
            to="/solutions"
            className={({ isActive }) =>
              `${styles.navbar__link} ${isActive ? styles.active : ""}`
            }
          >
            Solutions
          </NavLink>
          {activeDropdown === "solutions" && (
            <div
              className={styles.dropdownWrapper}
              onClick={(e) => e.stopPropagation()}
            >
              <CustomDropdownBar
                dropdownData={dropdownData.solutions}
                handleNavigation={(path) => {
                  console.log(`Item clicked with path: ${path}`);
                  setActiveDropdown("");
                  navigate(path);
                }}
              />
            </div>
          )}
        </li>
        <li
          className={styles.navbar__item}
          onMouseEnter={() => handleMouseEnter("platform")}
          onMouseLeave={handleMouseLeave}
        >
          <NavLink
            to="/platform"
            className={({ isActive }) =>
              `${styles.navbar__link} ${isActive ? styles.active : ""}`
            }
          >
            Platform
          </NavLink>
          {activeDropdown === "platform" && (
            <div
              className={styles.dropdownWrapper}
              onClick={(e) => e.stopPropagation()}
            >
              <CustomDropdownBar
                dropdownData={dropdownData.platform}
                handleNavigation={(path) => {
                  setActiveDropdown("");
                  navigate(path);
                }}
              />
            </div>
          )}
        </li>
        <li className={styles.navbar__item}>
          <NavLink
            to="/WhyActionFI"
            className={({ isActive }) =>
              `${styles.navbar__link} ${isActive ? styles.active : ""}`
            }
          >
            Why actionfi?
          </NavLink>
        </li>
        <li className={styles.navbar__item}>
          <NavLink
            to="/company"
            className={({ isActive }) =>
              `${styles.navbar__link} ${isActive ? styles.active : ""}`
            }
          >
            Company
          </NavLink>
        </li>
        <li className={styles.navbar__item}>
          <NavLink
            to="/partners"
            className={({ isActive }) =>
              `${styles.navbar__link} ${isActive ? styles.active : ""}`
            }
          >
            Partners
          </NavLink>
        </li>
        <li className={styles.navbar__item}>
          <NavLink
            to="/contactUs"
            className={({ isActive }) =>
              `${styles.navbar__link} ${isActive ? styles.active : ""}`
            }
          >
            Contact Us
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
