import React from 'react'
import styles from './footerBottom.module.css'
import gmailLogo from '../../../Assets/Images/gmail.svg'
import facebookLogo from '../../../Assets/Images/facebook.svg'
import LinkedinLogo from '../../../Assets/Images/linkdin.svg'
import XLogo from '../../../Assets/Images/Xlogo.svg'
const FooterBottom = () => {
  return (
    < div className={styles.bottomSection}>

      <div className={styles.footerBottom}>

        <div className={styles.footerBottomleft}>
          <div>
            <span className={styles.actionfibottom}>  actionfi</span>
            <p className={styles.addressParagraph}>
              C5 UL Cyber Park, Special Economic Zone,<br />Calicut, Kerala, India - 673016
            </p>
            <div className={styles.iconWrap}>

              <a href="tel:+16462906311">+91 495 297 3774</a>
            </div>
          </div>
          <div>
            <br></br>
            <p className={styles.addressParagraph}>
              MEDA Building, Kakkanad,<br />Ernakulam, Kerala, India - 682030
            </p>
            <div className={styles.iconWrap}>

              <a href="tel:+16462906311">+91 484 299 3399</a>
            </div>
          </div>
        </div>

        <div style={{ backgroundColor: "#F0F8FF",width:"30%",padding:"10px",paddingTop:"30px",paddingLeft:"24px" }}>
          <br/>
          <p className={styles.addressParagraph}>

            1441 Broadway, #5<br />
            New York, NY 10018<br />
          </p>
          <div className={styles.iconWrap}>



            <a href="tel:+16462906311">+1 646 290 6311</a>
          </div>
        </div>
      </div>
      <hr style={{ border: "1px solid #D9D9D9",marginTop:"0px ",margin:"0px 120px" }}></hr>
      <div className={styles.footerlinksSection}>
        <div className={styles.copyRight}>
          Copyrights &copy; actionFi
        </div>
        <div className={styles.footerIcons}>
          <span className={styles.LogoWrapper} > <a href="mailto:hello@actionfi.com" target="_blank" rel="noopener noreferrer">
            <img src={gmailLogo} alt="Logo" className={styles.footer__logoImg} />
          </a> </span>
          <span className={styles.LogoWrapper} > <a href="https://www.facebook.com/actionfi2015" target="_blank" rel="noopener noreferrer"> <img src={facebookLogo} alt="Logo" className={styles.footer__logoImg} /> </a></span>
          <span className={styles.LogoWrapper} >  <a href="https://www.linkedin.com/company/actionfi/" target="_blank" rel="noopener noreferrer"><img src={LinkedinLogo} alt="Logo" className={styles.footer__logoImg} /></a></span>
          <span className={styles.LogoWrapper} > <a href="https://x.com/actionfi_" target="_blank" rel="noopener noreferrer"> <img src={XLogo} alt="Logo" className={styles.footer__logoImg} /></a></span>
        </div>

      </div>
    </div>
  )
}

export default FooterBottom