

  
export const fetchEndusers = async (endpoint) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}${endpoint}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      if (!response.ok) {
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }
  
      return await response.json(); // Parse JSON data
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error; // Propagate error to handle it in the component
    }
  };