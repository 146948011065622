/*
purpose of this: homepage of website
Author: Jinto Jose
Completed date: 
*/


import React from 'react';
import { useEffect, useState } from 'react';
import styles from './homepage.module.css'; // Import the CSS module
import { useNavigate } from 'react-router-dom';
import runmybotmain from '.././Assets/Videos/runmybotmain.mp4'
import RotatingText from '../components/Layout/RotatingText';
import CounterDisplay from '../components/Layout/counter/CounterDisplay';
import platformVideo from '../Assets/Videos/Animation_for_RUNMYBOT1.mp4'
import TestimonialSection from '../components/Testimonials/TestimonialSection';
import whatwegoodat from '../Assets/Images/whatwegoodat.svg';
import MoreThanPlatform from '../components/morethanaplatform/MoreThanPlatform';
import ImageCarousel from '../components/AutomationRunsAt/ImageCarousel';
// import useGlobalTextReplace from '../useGlobalTextReplace';


const HomePage = () => {


    const texts = [
        "Scalable",
        "Purpose-built integration and automation as a service",
        "Fully managed to elevate your operations seamlessly",


        // Add more texts as needed
    ];
    const navigate = useNavigate()

    const featuresArray = ['Integration', 'AI Analytics', 'RPA Automations', 'Advanced Schedulers', 'Data Transformation'];
    const [currentIndex, setCurrentIndex] = useState(0);

    const [fade, setFade] = useState(true);
    const [grid, setGrid] = useState([]); // Holds the 4x4 grid of images
    useEffect(() => {
        // Check if there's a hash in the URL
        const hash = window.location.hash;
        if (hash) {
            const element = document.querySelector(hash);
            if (element) {
                // Scroll to the element with the corresponding id
                element.scrollIntoView({
                    behavior: 'smooth'


                });
            }
        }
    }, []);

      useEffect(() => {
        const interval = setInterval(() => {
          setFade(false); // Start fade-out animation
          setTimeout(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % featuresArray.length); // Change text
            setFade(true); // Start fade-in animation
          }, 500); // Match fade-out duration in CSS
        }, 5000); // Interval to switch text

        return () => clearInterval(interval);
      }, []);
    useEffect(() => {
        // Fetch image data from API
        const fetchImages = async () => {
            try {
                const response = await fetch("https://cms-v2.actionfi.com/api/end-users");
                if (!response.ok) {
                    throw new Error(`Error: ${response.statusText}`);
                }
                const data = await response.json();
                // Extract logo URLs from API response
                const images = data.end_users.map((user) => `https://cms-v2.actionfi.com/attachments/${user.logo}`);
                setGrid(images);
            } catch (err) {

            }
        };
        fetchImages();
    }, []);
    const handleExplorefurther = () => {
        navigate('/WhyActionFI')
    }
    const handleclick = () => {

        window.open(`https://exp.runmybot.com/accounts/login/`, "_blank", "noopener,noreferrer");

    }
    return (<>
        <div className={styles.homeContainerMain}>
            <div className={styles.homePage}>
                <div className={styles.textSection}>
                    <h1>The Smartest Way to
                        Connect Business Data</h1>
                    <div className="nonAnimation">
                        <p>Not just{' '} &nbsp;
                            <span className={`${styles.ovalBorder} ${fade ? styles.fadeIn : styles.fadeOut}`}>
                        {featuresArray[currentIndex]}
                    </span>{' '}
                            {/* <div className={styles.text_container}>
                                {featuresArray.map((item, index) => (
                                    <span
                                        key={index}
                                        style={{ '--i': index }}  // Dynamically set the animation delay based on index
                                    >
                                        {item}
                                    </span>
                                ))}
                            </div> */}
                            {' '}
                            , We do it all</p>
                    </div>

                    <button className={styles.ExpRmbButton} onClick={() => { handleclick() }} >Experience <span className={styles.run}>RUN</span><span className={styles.my}>MY</span><span className={styles.bot}>BOT®</span>  </button>
                </div>
                <div className={styles.imageSection}>
                    <video
                        autoPlay
                        loop
                        muted
                        style={{ width: '90%', height: 'auto' }}
                        playsInline
                    >
                        <source src={runmybotmain} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>

            </div>
            <div style={{ margin: "60px 0px ", textAlign: "center" }}>
                <RotatingText texts={texts} />
            </div>
            <div>
                <CounterDisplay />
            </div>
            <div className={styles.automationRunsat}>
                <p>Our automations run at</p>

                <div className={styles.sliders_wrapper}>
                    {grid.length > 0 ? (
                        <>
                            <ImageCarousel images={grid} showdiv={true} />

                        </>
                    ) : (
                        <p>Loading...</p>
                    )}


                </div>
            </div>
            <div className={styles.whyactionfi}>
                <h1 style={{ marginTop: "0px" }}>Why actionfi?</h1>

                <p>For the last 8+ years, actionfi has consistently earned the trust of the integration and automation software market. With an ever growing customer list of 180+global customers. Organisations use actionfi to automate their business through the integration of systems,CRM, and APIs to enable complicated and connect business processes</p>
                <div className={styles.explorefurther}>
                    <button onClick={handleExplorefurther} style={{ cursor: "pointer" }}>Explore further</button>
                </div>
            </div>
            <div >
                <div className={styles.whatwegoodat}>
                    <p >What are we good at?</p>
                    <div className={styles.imageSection}>
                        <img src={whatwegoodat} alt="What we are good at?" className={styles.image} />
                    </div>

                </div>
                <div id="testimonials" className={styles.testimonialContainer} >
                    <p>What our clients say about us.</p>
                    <TestimonialSection />
                </div>
                <div className={styles.ourPlatform}>
                    <h1 >Our Platform</h1>
                    <p>
                        The <span className='rmb_run_p'>RUN</span><span className='rmb_my_p'>MY</span><span className='rmb_bot_p'>BOT®</span> platform is fully managed from , PoC, solution design, implementation and 24/7 support and maintenance. Upgrades are covered in subscription fees. The platform provides intelligent insights into your processes and workflow to enable continuous improvement.
                    </p>
                    <video
                        autoPlay
                        loop
                        muted
                        style={{ width: '90%', height: 'auto' }}
                        playsInline
                    >
                        <source src={platformVideo} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>

                </div>
            </div>

        </div>
        <div className={styles.morethanplatform}>
            <p className={styles.morethanplatformp}>More than a platform</p>
            <MoreThanPlatform />
        </div>


        <div style={{ width: "100%", overflow: "hidden" }} className={styles.wave}>

        </div>
    </>

    );
};

export default HomePage;
