import React, { useState } from "react";
import styles from "./careerOpportunities.module.css";

const CareerOpportunities = ({ jobs }) => {
  const [expandedJob, setExpandedJob] = useState(null);

  const handleToggle = (id) => {
    setExpandedJob(expandedJob === id ? null : id);
  };
  const handleApplyNowClick = () => {
    const recipient = "hello@actionfi.com"; // Replace with the required email address
    const mailtoLink = `mailto:${recipient}`;
    
    // Open the mail client
    window.location.href = mailtoLink;
  };
  
  return (
    <div className={styles.careerContainer}>
      {jobs.careers.map((job) => (
        <div key={job.id} className={styles.card}>
          <div className={styles.cardHeader}>
            <h3 className={styles.title}>{job.title}</h3>
            <button className={styles.applyButton} onClick={handleApplyNowClick}>Apply Now</button>
          </div>
          <div
            dangerouslySetInnerHTML={{ __html: job.description }}
       
            className={styles.cardContents}
          />
         
          {expandedJob === job.id && (
            <div className={styles.details}>
              {job.rows.map((row, index) => (
                <div key={index} style={{ marginBottom: "20px" }}>
                  <h3>{row.title}</h3>
                  <div
                    dangerouslySetInnerHTML={{ __html: row.description }}
                    className={styles.expandedDescription}
                   
                  />
                </div>
              ))}
             
            </div>
          )}
          <div className={styles.buttonRow}>
            <button className={styles.readMoreButton} onClick={() => handleToggle(job.id)}>
              {expandedJob === job.id ? "Read Less" : "Read More"}
              
                {expandedJob === job.id ? (
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12 21V21C7.029 21 3 16.971 3 12V12C3 7.029 7.029 3 12 3V3C16.971 3 21 7.029 21 12V12C21 16.971 16.971 21 12 21Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M12 16L12 8" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M9 11L12 8L15 11" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              
                
                ) : (
                <>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M12 21V21C7.029 21 3 16.971 3 12V12C3 7.029 7.029 3 12 3V3C16.971 3 21 7.029 21 12V12C21 16.971 16.971 21 12 21Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
  <path d="M12 8L12 16" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
  <path d="M9 13L12 16L15 13" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
</svg>

                </>
                )}
             
            </button>
          </div>

        </div>
      ))}
    </div>
  );
};

export default CareerOpportunities;
