import React from "react";
import styles from "./bystystemscard.module.css"; // Import your CSS module

const CardsGrid = ({ cards,handleCardclick }) => {
  return (
    <div className={styles.grid}>
      {cards?.map((card) => (
        <div key={card.id} className={styles.box} onClick={()=>handleCardclick(card.id)}>
          <div className={styles.rectangle}>
            <div className={styles.img_container}>
            <img
              src={`${process.env.REACT_APP_API_BASE_URL}attachments/${card.logo}`}
              alt={card.title}
              className={styles.image}
            />
              </div>
           
            <div className={styles.label}>
              <span className={styles.textWrapper}>{card.title}</span>
            </div>
            <div className={styles.description}>
              <p className={styles.textWrapper}>{card?.description}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default CardsGrid;
