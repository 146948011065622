import React from 'react'
import { useState, useEffect } from 'react';
import functionHeader from '../../Assets/Images/Functions/functionHeader.svg'
import styles from './byFunctionpage.module.css'
import { fetchFunctions, fetchIntegrations, fetchWorkflows } from '../../apiServices/functions/apiCalls';
const ByFunctionPage = () => {
  const [details, setDetails] = useState(null);
  const [workflows, setWorkflows] = useState([])
  const [integrations, setIntegrations] = useState([])
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchFunctions();
        setDetails(data.functions);
      } catch (error) {
        console.error("Error fetching card details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  if (loading) return <div className={styles.loading_div}>Loading...</div>;
  if (!details) return <div className={styles.noDataFound} >No details found.</div>;
  const label = "We have solutions that make your lives easier";

  const heading = "By Function";
  const description =
    "Stay ahead of the competition with faster innovation. Speed up strategic projects to reach the market sooner. Embrace transformative technologies shaping the future, and automate routine tasks that don’t add value—freeing up resources for what matters most.";
  const gridData = [
    {
      id: 1,
      image: <svg className={styles.hover_effect} xmlns="http://www.w3.org/2000/svg" width="70" height="71" viewBox="0 0 70 71" fill="none">
        <path d="M41.5035 34.6943C51.1032 34.6943 58.9119 26.9124 58.9119 17.3472C58.9119 7.78199 51.1032 0 41.5035 0C31.9038 0 24.0938 7.78199 24.0938 17.3472C24.0938 26.9124 31.9038 34.6943 41.5035 34.6943ZM39.4417 7.15061V6.67947C39.4417 5.54408 40.364 4.62509 41.5035 4.62509C42.643 4.62509 43.5653 5.54408 43.5653 6.67947V7.15335C46.0188 7.6779 47.8648 9.85554 47.8648 12.455C47.8648 13.589 46.9411 14.5094 45.803 14.5094C44.6649 14.5094 43.7412 13.589 43.7412 12.455C43.7412 11.7305 43.1488 11.1402 42.4217 11.1402H40.573C39.8458 11.1402 39.2534 11.7305 39.2534 12.455C39.2534 12.87 39.4527 13.2644 39.7881 13.511L41.5035 14.7765L45.6711 17.8498C47.0401 18.8592 47.8648 20.4739 47.8758 22.1722V22.1914C47.8854 23.6404 47.3288 25.0073 46.3061 26.0386C45.5474 26.8042 44.5989 27.3178 43.5653 27.5341V28.0149C43.5653 29.1503 42.6416 30.0693 41.5035 30.0693C40.3654 30.0693 39.4417 29.1503 39.4417 28.0149V27.541C38.4356 27.3273 37.5105 26.8343 36.7628 26.0975C35.7278 25.0799 35.1532 23.7212 35.145 22.2722C35.1381 21.1382 36.0549 20.2123 37.193 20.2055H37.2067C38.3394 20.2055 39.2617 21.1176 39.2685 22.2475C39.2713 22.957 39.8431 23.5541 40.595 23.5541C41.7344 23.5473 41.3097 23.55 42.4409 23.5432C43.1722 23.5377 43.7577 22.946 43.7522 22.2188V22.1996C43.7495 21.7887 43.5502 21.397 43.2176 21.1519L41.5035 19.8878L37.3346 16.8144C35.9545 15.7954 35.1298 14.1656 35.1298 12.455C35.1298 9.85006 36.9813 7.67105 39.4417 7.15061Z" fill="url(#paint0_linear_1_22205)" />
        <path d="M13.2832 44.5622C12.754 43.6487 11.5802 43.3323 10.6592 43.8609L0.960584 49.4406C0.0424001 49.9707 -0.272367 51.1403 0.256826 52.0565L10.1685 69.1627C10.6991 70.0776 11.873 70.3912 12.7925 69.8639L22.4912 64.2842C23.4107 63.7556 23.7241 62.5859 23.1949 61.6697L13.2832 44.5635V44.5622Z" fill="url(#paint1_linear_1_22205)" />
        <path d="M68.8917 41.1515C67.9584 39.86 66.1523 39.5683 64.8575 40.4982C60.9291 43.3182 53.3857 48.7335 52.7864 49.165C52.5101 49.3992 52.2201 49.6114 51.9177 49.7991C50.7315 50.5428 49.3556 50.9399 47.9192 50.9399H38.0432C36.9051 50.9399 35.9814 50.0209 35.9814 48.8856C35.9814 47.7502 36.9065 46.8312 38.0432 46.8312H48.6051C50.1569 46.8312 51.3995 45.5438 51.3349 43.9948C51.2744 42.5252 50.0153 41.3857 48.5391 41.3857H40.4995C39.9552 40.8119 39.3586 40.2859 38.7195 39.8162C36.5216 38.2014 33.8056 37.2468 30.8654 37.2468C25.6381 37.2468 20.4369 40.5284 18.2803 44.9083L26.7144 59.4629H42.5352C45.6416 59.4629 48.7013 58.6713 51.394 57.1277C52.3273 56.5936 53.3266 55.9513 54.4097 55.1761C58.9484 51.9274 68.2278 45.1781 68.2347 45.174C69.5323 44.2454 69.8264 42.443 68.8917 41.1515Z" fill="url(#paint2_linear_1_22205)" />
        <defs>
          <linearGradient id="paint0_linear_1_22205" x1="41.5028" y1="0" x2="41.5028" y2="34.6943" gradientUnits="userSpaceOnUse">
            <stop offset="1" stop-color="#E27DDD" />
          </linearGradient>
          <linearGradient id="paint1_linear_1_22205" x1="11.726" y1="43.6047" x2="11.726" y2="70.1198" gradientUnits="userSpaceOnUse">
            <stop offset="1" stop-color="#E27DDD" />
          </linearGradient>
          <linearGradient id="paint2_linear_1_22205" x1="43.8592" y1="37.2468" x2="43.8592" y2="59.4629" gradientUnits="userSpaceOnUse">
            <stop offset="1" stop-color="#E27DDD" />
          </linearGradient>
        </defs>
      </svg>, label: "Finance"
    },
    {
      id: 2, image: <svg className={styles.hover_effect} xmlns="http://www.w3.org/2000/svg" width="68" height="71" viewBox="0 0 68 71" fill="none">
        <g clip-path="url(#clip0_1_22237)">
          <path d="M33.7462 4.98221H8.48821L11.7433 1.66812C12.7914 0.597865 14.2233 0 15.7216 0H51.7633C53.2617 0 54.6936 0.605246 55.7417 1.66812L58.9968 4.98221H33.7388H33.7462ZM65.433 67.4038C63.8756 69.1309 61.6613 70.12 59.3363 70.12H8.16344C3.66838 70.1348 0.0147621 66.4959 0 62.0008C0 61.6687 0.0147621 61.3366 0.0590484 61.0044L4.37696 14.9762C4.64268 12.1123 7.04891 9.92013 9.9349 9.92013H57.5722C60.4508 9.92013 62.857 12.1123 63.1301 14.9762L67.4481 61.0561C67.7064 63.359 66.9757 65.6618 65.4404 67.3964L65.433 67.4038ZM53.3133 22.741C53.3133 20.8662 51.7928 19.3457 49.9181 19.3457C48.0433 19.3457 46.5228 20.8662 46.5228 22.741V26.3577C46.5228 33.414 40.7951 39.1343 33.7388 39.1343C26.6825 39.1343 20.9696 33.414 20.9622 26.3577V22.741C20.9622 20.8662 19.4417 19.3457 17.5669 19.3457C15.6921 19.3457 14.1716 20.8662 14.1716 22.741V26.3577C14.1716 37.1636 22.9329 45.9249 33.7388 45.9175C44.5447 45.9175 53.2986 37.1562 53.2986 26.3577V22.741H53.3133Z" fill="#E27DDD" />
        </g>
        <defs>
          <clipPath id="clip0_1_22237">
            <rect width="67.4923" height="70.12" fill="white" />
          </clipPath>
        </defs>
      </svg>, label: "Retail"
    },
    {
      id: 3, image: <svg className={styles.hover_effect} xmlns="http://www.w3.org/2000/svg" width="73" height="71" viewBox="0 0 73 71" fill="none">
        <path d="M3.04235 0C1.36214 0 0 1.36476 0 3.0482C0 4.73164 1.36214 6.0964 3.04235 6.0964H8.45668C9.36525 6.0964 10.1725 6.67946 10.4592 7.54261L20.492 37.7031C20.937 39.0421 20.9941 40.484 20.6532 41.8531L19.8273 45.1699C18.615 50.0273 22.389 54.8761 27.3869 54.8761H63.9008C65.581 54.8761 66.9431 53.5114 66.9431 51.828C66.9431 50.1445 65.581 48.7798 63.9008 48.7798H27.3854C26.2002 48.7798 25.4399 47.8051 25.7266 46.6533L26.3499 44.161C26.5667 43.2921 27.3469 42.6819 28.2412 42.6819H60.857C62.1678 42.6819 63.3302 41.8431 63.7453 40.5983L71.8582 16.2085C72.5158 14.2335 71.0481 12.1942 68.9699 12.1957H19.9443C19.0357 12.1957 18.2284 11.6126 17.9417 10.7494L15.0591 2.08501C14.6455 0.838862 13.4816 0 12.1708 0H3.04235ZM24.3431 57.9243C20.9827 57.9243 18.257 60.6539 18.257 64.0222C18.257 67.3905 20.9812 70.12 24.3431 70.12C27.7049 70.12 30.4292 67.3905 30.4292 64.0222C30.4292 60.6539 27.7049 57.9243 24.3431 57.9243ZM60.857 57.9243C57.4966 57.9243 54.7709 60.6539 54.7709 64.0222C54.7709 67.3905 57.4952 70.12 60.857 70.12C64.2188 70.12 66.9431 67.3905 66.9431 64.0222C66.9431 60.6539 64.2188 57.9243 60.857 57.9243Z" fill="#E27DDD" />
      </svg>, label: "E-Commerce"
    },
    {
      id: 4, image: <svg className={styles.hover_effect} xmlns="http://www.w3.org/2000/svg" width="70" height="66" viewBox="0 0 70 66" fill="none">
        <g clip-path="url(#clip0_1_22241)">
          <path d="M1.92949 42.4363H9.64384C10.709 42.4363 11.5733 43.2989 11.5733 44.364V63.6535C11.5733 64.7186 10.709 65.583 9.64384 65.583H1.92949C0.864383 65.583 0 64.7186 0 63.6535V44.364C0 43.2989 0.864383 42.4363 1.92949 42.4363Z" fill="#E27DDD" />
          <path d="M21.2167 27.0039H28.9329C29.998 27.0039 30.8624 27.8683 30.8624 28.9334V63.6534C30.8624 64.7185 29.998 65.5829 28.9329 65.5829H21.2167C20.1516 65.5829 19.2891 64.7185 19.2891 63.6534V28.9334C19.2891 27.8683 20.1516 27.0039 21.2167 27.0039Z" fill="#E27DDD" />
          <path d="M40.5066 34.72H48.2228C49.2879 34.72 50.1505 35.5844 50.1505 36.6495V63.6533C50.1505 64.7184 49.2879 65.5828 48.2228 65.5828H40.5066C39.4415 65.5828 38.5771 64.7184 38.5771 63.6533V36.6495C38.5771 35.5844 39.4415 34.72 40.5066 34.72Z" fill="#E27DDD" />
          <path d="M59.7957 23.1467H67.5101C68.5752 23.1467 69.4395 24.0111 69.4395 25.0762V63.6534C69.4395 64.7185 68.5752 65.5829 67.5101 65.5829H59.7957C58.7306 65.5829 57.8662 64.7185 57.8662 63.6534V25.0762C57.8662 24.0111 58.7306 23.1467 59.7957 23.1467Z" fill="#E27DDD" />
          <path d="M63.6533 0C60.4598 0.00361667 57.8703 2.59315 57.8667 5.78667C57.8739 6.41597 57.986 7.04165 58.1976 7.63478L48.5411 13.3744C47.2734 12.0308 45.4362 11.378 43.6062 11.624C41.7743 11.8699 40.1739 12.9802 39.3041 14.6113L30.7869 10.3925C30.8285 10.1448 30.8538 9.8952 30.8628 9.64384C30.8664 7.30205 29.4596 5.18811 27.2968 4.28937C25.134 3.39063 22.6421 3.8843 20.9857 5.54073C19.3293 7.19717 18.8338 9.68724 19.7307 11.85L8.68362 20.0942C7.80838 19.5716 6.80657 19.2931 5.78667 19.2877C2.59134 19.2895 0 21.879 0 25.0762C0 28.2733 2.59134 30.8628 5.78667 30.8628C8.98199 30.8628 11.5733 28.2715 11.5733 25.0762C11.5679 24.38 11.4377 23.691 11.1864 23.0418L22.3438 14.7144C24.5264 15.9314 27.248 15.5752 29.0436 13.8356L38.7291 18.6331C39.3747 21.5083 42.0817 23.4378 45.0094 23.1123C47.9371 22.7868 50.1541 20.3094 50.1505 17.3618C50.1505 17.2153 50.118 17.0761 50.1071 16.9314L60.6099 10.6873C61.5213 11.2623 62.5756 11.5697 63.6533 11.5733C66.8487 11.5733 69.44 8.98199 69.44 5.78667C69.44 2.59134 66.8487 0 63.6533 0Z" fill="#E27DDD" />
        </g>
        <defs>
          <clipPath id="clip0_1_22241">
            <rect width="69.44" height="65.5828" fill="white" />
          </clipPath>
        </defs>
      </svg>, label: "Data Analytics"
    },
    {
      id: 5, image: <svg className={styles.hover_effect} xmlns="http://www.w3.org/2000/svg" width="73" height="73" viewBox="0 0 73 73" fill="none">
        <path d="M15.3443 52.4461C14.9303 51.927 14.1741 51.843 13.655 52.257C13.136 52.6711 13.0519 53.4272 13.466 53.9463C16.051 57.1839 19.299 59.8333 22.9912 61.7147C23.5703 62.0372 24.3009 61.8287 24.6234 61.2496C24.946 60.6705 24.7375 59.9399 24.1584 59.6173C24.1314 59.6023 24.1044 59.5888 24.0759 59.5753C20.6898 57.8485 17.7132 55.4181 15.3443 52.4461Z" fill="#E27DDD" />
        <path d="M16.855 14.4896C14.7396 16.3724 12.9123 18.5568 11.4331 20.9722C11.0955 21.5423 11.2845 22.2789 11.8546 22.6165C12.4127 22.9466 13.1328 22.774 13.4794 22.2264C14.8401 20.0135 16.5174 18.0137 18.4603 16.2899C18.9569 15.8488 19.0034 15.0882 18.5623 14.5916C18.1212 14.095 17.3606 14.0485 16.864 14.4896H16.8565H16.855Z" fill="#E27DDD" />
        <path d="M61.8869 23.341C60.9552 21.4401 59.815 19.6473 58.4903 17.9955C58.0927 17.4659 57.3396 17.3594 56.81 17.757C56.2804 18.1546 56.1739 18.9077 56.5714 19.4373C56.5864 19.4568 56.6015 19.4763 56.618 19.4958C57.8332 21.0111 58.8773 22.6538 59.7325 24.3971C60.0415 24.9837 60.7677 25.2073 61.3543 24.8982C61.9154 24.6012 62.1494 23.9186 61.8869 23.341Z" fill="#E27DDD" />
        <path d="M48.979 60.4109C49.3151 60.9825 50.0502 61.1745 50.6218 60.8385C50.6248 60.837 50.6263 60.8355 50.6293 60.834C53.9119 58.9031 56.7774 56.3392 59.0608 53.2922C59.4583 52.7626 59.3518 52.0094 58.8207 51.6119C58.2896 51.2143 57.538 51.3208 57.1404 51.8519C55.0476 54.6469 52.4191 56.9978 49.4096 58.7681C48.8365 59.1027 48.6429 59.8363 48.9775 60.4094C48.9775 60.4094 48.9775 60.4109 48.979 60.4124V60.4109Z" fill="#E27DDD" />
        <path d="M40.2881 0H25.212C23.2241 0 21.6113 1.61278 21.6113 3.60063V17.115C21.6113 19.1028 23.2241 20.7156 25.212 20.7156H26.5502C27.2118 22.4064 29.1201 23.239 30.8094 22.5774C31.6616 22.2429 32.3367 21.5692 32.6712 20.7156H44.8654C45.527 22.4049 47.4323 23.239 49.1216 22.5789C49.9753 22.2444 50.6504 21.5692 50.9849 20.7156H53.1528C53.8159 20.7156 54.353 20.1785 54.353 19.5154V12.3141C54.353 12.1461 54.317 11.9796 54.248 11.8251L51.663 6.03705C51.303 5.23141 50.5048 4.70932 49.6212 4.70482H43.8887V3.60063C43.8887 1.61278 42.2759 0 40.2881 0ZM41.4883 18.3152H32.6772C32.0066 16.6199 30.0893 15.7887 28.394 16.4594C27.5449 16.7954 26.8727 17.4675 26.5382 18.3152H25.212C24.5488 18.3152 24.0117 17.7781 24.0117 17.115V14.4025H41.4823L41.4883 18.3152ZM51.9556 13.9104V18.3152H50.9969C50.3263 16.6199 48.409 15.7887 46.7137 16.4594C45.8645 16.7954 45.1924 17.4675 44.8579 18.3152H43.8887V13.9104H51.9556ZM49.5162 7.10523L51.4815 11.51H43.8887V7.10523H49.5162Z" fill="#E27DDD" />
        <path d="M70.8195 28.8049H54.0166C53.3535 28.8049 52.8164 29.342 52.8164 30.0051V46.8081C52.8164 47.4712 53.3535 48.0083 54.0166 48.0083H70.8195C71.4826 48.0083 72.0197 47.4712 72.0197 46.8081V30.0051C72.0197 29.342 71.4826 28.8049 70.8195 28.8049ZM66.8438 35.082C66.8438 35.7451 66.3067 36.2822 65.6436 36.2822H59.1925C58.5294 36.2822 57.9923 35.7451 57.9923 35.082V31.2053H60.3927V33.8818H64.4434V31.2053H66.8438V35.082Z" fill="#E27DDD" />
        <path d="M1.20771 48.0084H25.2119C25.875 48.0084 26.4121 47.4713 26.4121 46.8082V29.6182C26.4391 28.751 25.4279 28.1314 24.6658 28.55L18.0991 31.8926L18.3017 29.7277C18.4157 28.808 17.3445 28.0924 16.5404 28.556L9.39163 32.3727L8.28294 22.669C8.21393 22.0629 7.70234 21.6038 7.09173 21.6038H2.51294C1.89933 21.5993 1.38174 22.0599 1.31273 22.669L0.0075013 34.125C0.00150026 34.17 0 34.2165 0 34.2615V46.8082C0 47.4713 0.537093 48.0084 1.20021 48.0084C1.20321 48.0084 1.20471 48.0084 1.20771 48.0084ZM20.411 39.6069C21.0742 39.6069 21.6113 40.144 21.6113 40.8071C21.6113 41.4702 21.0742 42.0073 20.411 42.0073C19.7479 42.0073 19.2108 41.4702 19.2108 40.8071C19.2108 40.144 19.7479 39.6069 20.411 39.6069ZM13.2098 39.6069C13.8729 39.6069 14.41 40.144 14.41 40.8071C14.41 41.4702 13.8729 42.0073 13.2098 42.0073C12.5467 42.0073 12.0096 41.4702 12.0096 40.8071C12.0096 40.144 12.5467 39.6069 13.2098 39.6069ZM6.00854 39.6069C6.67166 39.6069 7.20875 40.144 7.20875 40.8071C7.20875 41.4702 6.67166 42.0073 6.00854 42.0073C5.34543 42.0073 4.80833 41.4702 4.80833 40.8071C4.80833 40.144 5.34543 39.6069 6.00854 39.6069Z" fill="#E27DDD" />
        <path d="M31.1782 61.7552C28.2017 63.509 26.0773 66.7975 25.6738 70.6682C25.5987 71.3343 26.0773 71.9359 26.7434 72.0109C26.7869 72.0154 26.8305 72.0184 26.874 72.0184H45.5432C46.2138 72.0154 46.7539 71.4693 46.7509 70.7987C46.7509 70.7552 46.7479 70.7117 46.7434 70.6682C46.3383 66.805 44.214 63.5075 41.236 61.7537C40.7544 61.4731 40.1453 61.5466 39.7432 61.9322C38.9106 62.6988 37.6428 63.1789 36.2041 63.1789C34.7653 63.1789 33.5036 62.7003 32.6755 61.9367C32.2734 61.5481 31.6613 61.4746 31.1782 61.7552Z" fill="#E27DDD" />
        <path d="M39.6144 58.4621V53.8218C39.6144 52.5406 38.0901 51.5024 36.2103 51.5024C34.3305 51.5024 32.8047 52.5406 32.8047 53.8218V58.4621C32.8047 59.7434 34.329 60.7816 36.2103 60.7816C38.0916 60.7816 39.6144 59.7434 39.6144 58.4621Z" fill="#E27DDD" />
      </svg>, label: "Supply Chain"
    },
    {
      id: 6, image: <svg className={styles.hover_effect} xmlns="http://www.w3.org/2000/svg" width="73" height="73" viewBox="0 0 73 73" fill="none">
        <g clip-path="url(#clip0_1_22259)">
          <path d="M69.9098 4.21992C71.076 4.21992 72.0198 3.27607 72.0198 2.10996C72.0198 0.943856 71.076 0 69.9098 0H36.0098C34.8437 0 33.8998 0.943856 33.8998 2.10996C33.8998 3.27607 34.8437 4.21992 36.0098 4.21992H46.5596V17.0204H44.4496C43.8898 17.0204 43.3539 17.2426 42.9572 17.6379L34.5174 26.0777C34.1221 26.473 33.8984 27.0089 33.8984 27.5687V36.0086C33.8984 36.5684 34.1207 37.1044 34.5174 37.4996L40.2297 43.212V37.245L38.1198 35.1351V28.4423L45.3232 21.2389H60.5965L67.7999 28.4423V35.1351L65.6899 37.245V43.212L71.4023 37.4996C71.7976 37.1044 72.0198 36.5684 72.0198 36.0072V27.5673C72.0198 27.0075 71.7976 26.4716 71.4023 26.0763L62.9624 17.6365C62.5672 17.2412 62.0312 17.0189 61.47 17.0189H59.36V4.21992H69.9098Z" fill="#E27DDD" />
          <path d="M63.4395 55.1404H8.58051C3.9189 55.1404 0 58.9186 0 63.5802C0 68.2418 3.9189 72.0201 8.58051 72.0201H63.4395C68.1011 72.0201 72.02 68.2418 72.02 63.5802C72.02 58.9186 68.1011 55.1404 63.4395 55.1404ZM10.6905 65.6902C9.52436 65.6902 8.58051 64.7449 8.58051 63.5802C8.58051 62.4155 9.52436 61.4703 10.6905 61.4703C11.8566 61.4703 12.8004 62.4155 12.8004 63.5802C12.8004 64.7449 11.8552 65.6902 10.6905 65.6902ZM19.1303 65.6902C17.9642 65.6902 17.0204 64.7449 17.0204 63.5802C17.0204 62.4155 17.9642 61.4703 19.1303 61.4703C20.2964 61.4703 21.2403 62.4155 21.2403 63.5802C21.2403 64.7449 20.295 65.6902 19.1303 65.6902ZM27.5702 65.6902C26.4041 65.6902 25.4602 64.7449 25.4602 63.5802C25.4602 62.4155 26.4041 61.4703 27.5702 61.4703C28.7363 61.4703 29.6801 62.4155 29.6801 63.5802C29.6801 64.7449 28.7349 65.6902 27.5702 65.6902ZM36.01 65.6902C34.8439 65.6902 33.9 64.7449 33.9 63.5802C33.9 62.4155 34.8439 61.4703 36.01 61.4703C37.1761 61.4703 38.12 62.4155 38.12 63.5802C38.12 64.7449 37.1747 65.6902 36.01 65.6902ZM44.4498 65.6902C43.2837 65.6902 42.3399 64.7449 42.3399 63.5802C42.3399 62.4155 43.2837 61.4703 44.4498 61.4703C45.6159 61.4703 46.5598 62.4155 46.5598 63.5802C46.5598 64.7449 45.6145 65.6902 44.4498 65.6902ZM52.8897 65.6902C51.7236 65.6902 50.7797 64.7449 50.7797 63.5802C50.7797 62.4155 51.7236 61.4703 52.8897 61.4703C54.0558 61.4703 54.9996 62.4155 54.9996 63.5802C54.9996 64.7449 54.0544 65.6902 52.8897 65.6902ZM61.3295 65.6902C60.1634 65.6902 59.2196 64.7449 59.2196 63.5802C59.2196 62.4155 60.1634 61.4703 61.3295 61.4703C62.4956 61.4703 63.4395 62.4155 63.4395 63.5802C63.4395 64.7449 62.4942 65.6902 61.3295 65.6902Z" fill="#E27DDD" />
          <path d="M25.4602 33.9001H12.8004C11.6343 33.9001 10.6904 34.844 10.6904 36.0101V50.9205H27.5701V36.0101C27.5701 34.844 26.6263 33.9001 25.4602 33.9001Z" fill="#E27DDD" />
          <path d="M59.3606 33.9001H46.5602C45.3941 33.9001 44.4502 34.844 44.4502 36.0101V50.9205H61.4705V36.0101C61.4705 34.844 60.5267 33.9001 59.3606 33.9001Z" fill="#E27DDD" />
        </g>
        <defs>
          <clipPath id="clip0_1_22259">
            <rect width="72.02" height="72.02" fill="white" />
          </clipPath>
        </defs>
      </svg>, label: "Manufacturing"
    },
  ];
  const handleItemClick = async (itemId) => {
    setIntegrations([])
    setWorkflows([])
    try {
      // Call First API
      const data1 = await fetchWorkflows(itemId);
      console.log("First API response:", data1);
      setWorkflows(data1.workflows)
      // Call Second API
      const data2 = await fetchIntegrations(itemId);
      setIntegrations(data2.integrations)
      console.log("Second API response:", data2);
    } catch (error) {
      console.error("Error calling APIs:", error);
    }
  };
  return (
    <div className={styles.functionpageContainer}>
      {/* Heading Section */}
      <h1 className={styles.heading}>{heading}</h1>

      {/* Content Section */}
      <div className={styles.contentContainer}>
        {/* Image on the Left */}
        <div className={styles.imageContainer}>
          <img src={functionHeader} alt={label} className={styles.image} />
        </div>

        {/* Label and Description on the Right */}
        <div className={styles.textContainer}>
          <div className={styles.label}>{label}</div>
          <p className={styles.description}>{description}</p>
        </div>
      </div>

      {/* Grid Section */}
      <div className={styles.gridContainer}>
        {details.map((item) => {
          // Find the matching image data from gridData
          const matchedImage = gridData.find((data) => data.label === item.system);
          
          return (
            <div key={item.id} className={styles.gridItem}>
              {/* Render raw HTML for the image */}
              <div
                className={styles.gridItemImage}
                onClick={() => handleItemClick(item.id)}
             
              >
              {matchedImage.image}
              {/* Render the label */}
              </div>
              <div className={styles.gridLabel}>{item.system}</div>
            </div>
          );
        })}
      </div>

    {workflows && workflows.length>0 && (
        <div className={styles.workflowsContainer}>
          <h2>Workflows</h2>
          <div className={styles.gridContainer}>
            {workflows?.map((item) => (
              <div key={item.id} className={styles.gridItem}>
                <div className={styles.gridItemImage} onClick={() => { handleItemClick(item.id) }}>
                  <img
                    src={`${process.env.REACT_APP_API_BASE_URL}attachments/${item.logo}`}
                    alt={item.title}
                    className={styles.image}
                  />
                </div>
                <div className={styles.gridLabel}>{item.title}</div>
              </div>
            ))}
          </div>
        </div>
    )}
      {integrations && integrations.length>0 &&(
        <div className={styles.workflowsContainer}>
        <h2>Integrations</h2>
        <div className={styles.integrationgrid}>
          {integrations?.map((card) => (
            <div key={card.id} className={styles.box}>
              <div className={styles.rectangle}>
                <img
                  src={`${process.env.REACT_APP_API_BASE_URL}attachments/${card.logo}`}
                  alt={card.title}
                  className={styles.image}
                />
                <div className={styles.label}>
                  <span className={styles.textWrapper}>{card.title}</span>
                </div>
                {/* <div className={styles.description}>
                  <p className={styles.textWrapper}>{card.description}</p>
                </div> */}
              </div>
            </div>
          ))}
        </div>
      </div>
      )}
      
    </div>
  );
}

export default ByFunctionPage