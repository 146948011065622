import React, { useState, useEffect, useRef } from 'react';
import styles from './RotatingText.module.css';

const RotatingText = ({ texts }) => {
  const [currentIndex, setCurrentIndex] = useState(0); // Start at index 0
  const textRefs = useRef([]);
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % texts.length); // Rotate through the list
    }, 3000); // Change every 3 seconds

    return () => clearInterval(intervalId); // Clear interval on component unmount
  }, [texts]);

  useEffect(() => {
    const highlightText = () => {
      textRefs.current.forEach((ref, index) => {
        if (ref) {
          // Apply highlighted class to the center (currentIndex) and lighter to others
          if (index === 1) {
            ref.classList.add(styles.highlighted);
            ref.classList.remove(styles.lighter);
          } else {
            ref.classList.add(styles.lighter);
            ref.classList.remove(styles.highlighted);
          }
        }
      });
    };

    highlightText();
  }, [currentIndex]);

  return (
    <div className={styles.RotatingTextcontainer}>
      {/* Show three items at a time */}
      {texts
        .slice(currentIndex, currentIndex + 3)
        .concat(texts.slice(0, Math.max(currentIndex + 3 - texts.length, 0)))
        .map((text, index) => (
          <p
            key={index}
            className={styles.text}
            ref={(el) => (textRefs.current[index] = el)} // Assign ref to each element
          >
            {text}
          </p>
        ))}
    </div>
  );
};

export default RotatingText;
