import React from "react";
import { NavLink } from "react-router-dom";
import styles from "./customdropdownbar.module.css";

const CustomDropdownBar = ({ dropdownData, handleNavigation }) => {
  const handleTitleClick = (path) => {
    if (path) {
      handleNavigation(path); // Call handleNavigation passed from Navbar
    }
  };

  const handleItemClick = (path, e) => {
    e.preventDefault(); // Prevent default behavior
    if (path) {
      handleNavigation(path); // Navigate to the item path
    }
  };

  return (
    <div className={styles.dropdownContainer}>
      {dropdownData.map((section, index) => (
        <div key={index} className={styles.section}>
          {/* Title */}
          <h3 onClick={() => handleTitleClick(section.path)}>{section.title}</h3>
      {section.items && (<>
        <hr />

       
<ul>
  {section.items?.map((item, idx) => (
    <li key={idx}>
      <NavLink
        to={item.path}
        onClick={(e) => handleItemClick(item.path, e)} // Optional for additional logic
        className={({ isActive }) => (isActive ? styles.activeLink : "")}
      >
        {item.label}
      </NavLink>
    </li>
  ))}
  <div className={styles.viewAllbuttton} onClick={() => handleTitleClick(section.path)}><svg style={{width:"25px"}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25"><path style={{fill:"#232326"}} d="m17.5 5.999-.707.707 5.293 5.293H1v1h21.086l-5.294 5.295.707.707L24 12.499l-6.5-6.5z" data-name="Right"/></svg>
  {/* <svg  className={styles.uparrow} xmlns="http://www.w3.org/2000/svg" width="20" height="20"><path d="M7 7h8.586L5.293 17.293l1.414 1.414L17 8.414V17h2V5H7v2z"/></svg> */}
  </div>
</ul>

      </>
       
      )}
          
        </div>
      ))}
    </div>
  );
};

export default CustomDropdownBar;
