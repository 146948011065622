import React from 'react'
import { useState,useEffect } from 'react';
import { useParams } from 'react-router-dom';
import integrationHeader from '../../../Assets/Images/Integration/Integrationheader.svg'
import styles from './integrationTile.module.css'
import { fetchIntegrationDetails } from '../../../apiServices/Integrations/apiCalls';

const cards = [
    {
      id: 1,
      image: "https://via.placeholder.com/41x20",
      label: "Act-on - NetSuite",
      description: "Sync Act-On Contracts to NetSuite leads"
    },
    {
      id: 2,
      image: "https://via.placeholder.com/41x20",
      label: "3PL Central - NetSuite",
      description: "Streamline your order-to-cash processes and eliminate manual data entry"
    },
    {
      id: 3,
      image: "https://via.placeholder.com/41x20",
      label: "ADP - NetSuite",
      description: "Seamlessly integrate Payroll and Employee data from ADP into NetSuite"
    },
    {
      id: 4,
      image: "https://via.placeholder.com/41x20",
      label: "ADP - Workforce Now (API) - NetSuite",
      description: "Automate your back-office HR processes and eliminate manual data entry"
    },
    {
      id: 5,
      image: "https://via.placeholder.com/41x20",
      label: "ADP - Workforce Now (FTP) - NetSuite",
      description: "Automate administrative HR processes and eliminate time-consuming manual processes and data entry"
    },
    {
      id: 6,
      image: "https://via.placeholder.com/41x20",
      label: "Amazon Vendor Central EDI (FTP) -NetSuite",
      description: "Automate administrative HR processes and eliminate time-consuming manual processes and data entry"
    }
  ];

const IntegrationTile = () => {
  const { id} = useParams();

  const [details, setDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchIntegrationDetails(id);
        setDetails(data.integrations);
      } catch (error) {
        console.error("Error fetching card details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  if (loading) return <div className={styles.loading_div}>Loading...</div>;
  if (!details) return <div className={styles.noDataFound} >No details found.</div>;
  return (
    <div className={styles.IntegrationtileContainer}>
    <div className={styles.contentContainer}>
        <img src={integrationHeader} alt='Integration'/>
        <h1 className={styles.heading}>ERP Integration</h1>
        <p className={styles.description} style={{paddingBottom:"7rem"}}>
        Explore and discover new automation possibilities with our extensive library of ready-to-use integrations with ERP
        </p>
        <h1 className={styles.heading2}>Browse Our Prebuilt ERP integrations</h1>
       <p className={styles.description}>
       Explore and discover new automation possibilities with our extensive library of ready-to-use integrations with ERP
       </p>
    </div> 
    <div className={styles.integrationgrid}>
      {details?.map((card) => (
        <div key={card.id} className={styles.box}>
          <div className={styles.rectangle}>
            <img
              src={`${process.env.REACT_APP_API_BASE_URL}attachments/${card.image}`}
              alt={card.title}
              className={styles.image}
            />
            <div className={styles.label}>
              <span className={styles.textWrapper}>{card.title}</span>
            </div>
            <div className={styles.description}>
              <p className={styles.textWrapper}>{card.description}</p>
            </div>
          </div>
        </div>
      ))}
    </div>

    
  
   
</div>
  )
}

export default IntegrationTile