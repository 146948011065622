import React from 'react';
import { useEffect } from 'react';
import Navbar from './Header/Navbar';
// import Footer from './Footer'; // Import Footer component
import { Outlet } from 'react-router-dom'; // Import Outlet for nested routes
import styles from './layout.module.css'; // Import CSS module for layout styling
import Footer from './Footer/Footer';
import FooterBottom from './Footer/FooterBottom';

const Layout = () => {

  return (


    <>
      <div className={styles.layoutContainer}>
        {/* Header Section */}

        <Navbar />
      </div>
      {/* Main Content Section */}
      <main className={styles.mainContent}>
          <Outlet /> {/* Renders the matched child route content here */}
        </main>

        {/* Footer Section */}
      <footer className={styles.footer}>
       
        <Footer />
       
       
      </footer>
      <footer className={styles.FooterBottomSection}>
        
        <FooterBottom /> 
        
       
      </footer>
      
    </>

  );
};

export default Layout;
