import React from 'react';
import { useEffect } from 'react';
import { BrowserRouter as Router,Route,Routes  } from 'react-router-dom';
import { ScrollProvider,} from './Utils/ScrollContext';
import './App.css';
import Layout from './components/Layout/Layout';
import HomePage from './pages/HomePage';
import SolutionPage from './pages/Solutions/SolutionPage';
import WhyActionfi from './pages/WhyActionfi/WhyActionfi';
import CompanyPage from './pages/Company/CompanyPage';
import PartnerPage from './pages/Partners/PartnerPage';
import AianalyzePage from './pages/Aianalyzepage/AianalyzePage';
import AutomationLibraryInner from './pages/Automationlibrary/AutomationLibraryInner';
import PlatformPage from './pages/Platform/PlatformPage';
import ConnectPage from './pages/Connect/ConnectPage';
import RunmybotsapPage from './pages/RunmybotforSAP/RunmybotsapPage';
import RunmybotEventpropage from './pages/RunmybotEventpro/RunmybotEventpropage';
import PartnerSignup from './pages/PartnerSignUp/PartnerSignup';
import PartnerSignIn from './pages/PartnerSignIn/PartnerSignIn';
import CareersPage from './pages/Careers/CareersPage';
import  WorkflowsPage  from './pages/ByWorkflows/WorkflowsPage';
import BysystemsPage from './pages/BySystems/BysystemsPage';
import IntegrationTile from './pages/Integration/IntegrationTile/IntegrationTile';
import ByFunctionPage from './pages/ByFunction/ByFunctionPage';
import ContactUspage from './pages/ContactUs/ContactUspage';
import ScheduleDemopage from './pages/ScheduleDemo/ScheduleDemopage';
import DataWranglerPage from './pages/Datawrangler/DataWranglerPage';
import EndorPage from './pages/Endor/EndorPage';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import ScrollToTop from './components/ScrollToTop';
import TermsofUse from './pages/Termsofuse/TermsofUse';
import MovingBackground from './components/Animations/MovingBackground';
import AutomationLibraryPage from './pages/Automationlibrary/AutomationLibraryPage';
import TestimonialSection from './components/Testimonials/TestimonialSection';
/*
purpose of this: 
Author: Jinto Jose
Completed date: 
*/

function App() {
  
  const replaceText = () => {
    // Target all <p> elements
    const paragraphs = document.querySelectorAll("p,h3");

    paragraphs.forEach((p) => {
      // Check if the paragraph contains the text 'run my bot'
      const regex = /\bRUNMYBOT\b/g;

      if (regex.test(p.innerHTML)) {
        p.innerHTML = p.innerHTML.replace(
          regex,
          `<span class="rmb_run_p">RUN</span><span class="rmb_my_p">MY</span><span class="rmb_bot_p">BOT®</span>           
           `
        );
      }
    });
  };

  useEffect(() => {
    // Initial text replacement on component mount
    replaceText();

    // Set up MutationObserver to watch for changes in the body of the document
    const observer = new MutationObserver(() => {
      replaceText(); // Re-run the text replacement when content changes
    });

    // Observe changes in the entire document body (or any specific container)
    observer.observe(document.body, {
      childList: true, // Observe addition/removal of child nodes
      subtree: true,   // Observe all descendants of the body
      characterData: true, // Detect changes in text content
    });

    // Cleanup observer on component unmount
    return () => {
      observer.disconnect(); // Disconnect the observer when the component is unmounted
    };
  }, []);
  return (
  <Router>
      <ScrollToTop /> {/* Ensure this is included */}
      <MovingBackground />
      <ScrollProvider>
      <Routes>
      
        <Route path="/" element={<Layout />} >
        <Route index element={<HomePage />}/>
        <Route path="solutions" element={<SolutionPage />} />
        <Route path="WhyActionFI" element={<WhyActionfi />} />
        <Route path="company" element={<CompanyPage />} />
        <Route path="partners" element={<PartnerPage />} />
        <Route path="aiAnalyze" element={<AianalyzePage />} />
        <Route path="automation-library/:slug1/:slug2" element={<AutomationLibraryInner />} />
        <Route path="platform" element={<PlatformPage />} />
        <Route path="runmybotforsap" element={<RunmybotsapPage />} />
        <Route path="connect" element={<ConnectPage />} />
        <Route path="runmybotEventpro" element={<RunmybotEventpropage />} />
        <Route path="partner/Signup" element={<PartnerSignup />} />
        <Route path="partner/SignIn" element={<PartnerSignIn />} />
        <Route path="careers" element={<CareersPage />} />
        <Route path="workflows" element={<WorkflowsPage />} />
        <Route path="Systems" element={<BysystemsPage />} />
        <Route path="Integration/:id" element={<IntegrationTile />} />
        <Route path="functions" element={<ByFunctionPage />} />
        <Route path="contactUs" element={<ContactUspage />} />
        <Route path="scheduledemo" element={<ScheduleDemopage />} />
        <Route path="dataWrangler" element={<DataWranglerPage />} />
        <Route path="workflowBuilder" element={<EndorPage />} />
        <Route path="privacypolicy" element={<PrivacyPolicy />} />
        <Route path="termsofuse" element={<TermsofUse />} />
        <Route path="testimonials" element={<TestimonialSection />} />
        <Route path="AutomationLibrary" element={<AutomationLibraryPage />} />
      </Route>
    </Routes>
      </ScrollProvider>
 
  </Router>
     
  );
}

export default App;
