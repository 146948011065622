import React from 'react';
import styles from './footer.module.css';
import { NavLink, useNavigate } from "react-router-dom";
import actionfilogo from '../../../Assets/Images/actionfiLogo.png';


const Footer = () => {
  const navigate = useNavigate();



  return (
    <>



      
      
      <div className={styles.footerTopLeft}>

        <div className={styles.footerLogo}>
          <img src={actionfilogo} alt="ActionFi Logo" />
        </div>
        <div className={styles.footerTagline}>
          Grow your business with <br />
          a personal AI manager
        </div>
      </div>


      <div className={styles.navList}>
        <div className={styles.navItem}>
          <NavLink
            to="platform"
            className={({ isActive }) =>
              `${styles.navLink} ${isActive ? styles.active : ""}`
            }
          >
            Platform
          </NavLink>
        </div>
        <div className={styles.navItem}>
          <NavLink
            to="/company"
            className={({ isActive }) =>
              `${styles.navLink} ${isActive ? styles.active : ""}`
            }
          >
            Company
          </NavLink>
        </div>
        <div className={styles.navItem}>
          <NavLink
            to="termsofuse"
            className={({ isActive }) =>
              `${styles.navLink} ${isActive ? styles.active : ""}`
            }
          >
            Terms of Service
          </NavLink>
        </div>
        <div className={styles.navItem}>
          <NavLink
            to="solutions"
            className={({ isActive }) =>
              `${styles.navLink} ${isActive ? styles.active : ""}`
            }
          >
            Solutions
          </NavLink>
        </div>
        <div className={styles.navItem}>
          <NavLink
            to="/WhyActionFI"
            className={({ isActive }) =>
              `${styles.navLink} ${isActive ? styles.active : ""}`
            }
          >
            About Us
          </NavLink>
        </div>
        <div className={styles.navItem}>
          <NavLink
            to="privacypolicy"
            className={({ isActive }) =>
              `${styles.navLink} ${isActive ? styles.active : ""}`
            }
          >
            Privacy Policy
          </NavLink>
        </div>
        <div className={styles.navItem}>
          <a
          href='/#testimonials'
          //  onClick={() => handleNavigation('testimonials')}
            className={({ isActive }) =>
              `${styles.navLink} ${isActive ? styles.active : ""}`
            }
            // onClick={()=>handleNavigateToTestimonials}
          >
            Testimonial
          </a>
        </div>

        <div className={styles.navItem}>
          <NavLink
            to="/careers"
            className={({ isActive }) =>
              `${styles.navLink} ${isActive ? styles.active : ""}`
            }
          >
            Careers
          </NavLink>
        </div>

        <div className={styles.navItem}>
          <NavLink
            to="https://www.youtube.com/watch?v=o0OBse7UBG0"
            rel="noopener noreferrer"
            target="_blank"
            className={({ isActive }) =>
              `${styles.navLink} ${isActive ? styles.active : ""}`
            }
          >
            Videos
          </NavLink>
        </div>
        <div className={styles.navItem}>
          <NavLink
            to="contactUs"
            className={({ isActive }) =>
              `${styles.navLink} ${isActive ? styles.active : ""}`
            }
          >
            Contact Us
          </NavLink>
        </div>
      </div>






    </>
  );
};

export default Footer;
