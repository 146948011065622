import React from 'react';
import styles from './casestudies.module.css'; // Import CSS styles for case studies
import { useState,useEffect } from 'react';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import { fetchCaseStudies } from '../../apiServices/CaseStudies/apiCalls';
const CaseStudies = () => {
  // JSON data inside the component
  const [caseStudies, setCaseStudies] = useState([]);
  console.log(caseStudies)
  const [hasNext, setHasNext] = useState(0); // Tracks if more pages are available
  const [currentPage, setCurrentPage] = useState(1); // Tracks the current page

  // Fetch initial dropdown data and card data
  useEffect(() => {
    async function fetchData() {
    
      const response = await fetchCaseStudies(1);
      setCaseStudies(response.case_studies); // Set the data to state
      setHasNext(response?.has_next || 0);
    }

    fetchData();
  }, []);
  const loadMoreCards = async () => {
    const nextPage = currentPage + 1;
    const response = await fetchCaseStudies( nextPage);
    setCaseStudies((prevCards) => [
      ...prevCards,
      ...(response?.case_studies || []),
    ]);
    setHasNext(response?.has_next || 0);
    setCurrentPage(nextPage);
  };

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await fetch('https://cms-v2.actionfi.com/api/case-study?page=1'); // Replace with your API endpoint
  //       if (!response.ok) {
  //         throw new Error('Failed to fetch data');
  //       }
  //       const data = await response.json();
  //       setCaseStudies(data.case_studies); // Set the data to state
  //       console.log('data ',data)
  //     } catch (error) {
        
    
  //     }
  //   };

  //   fetchData(); // Call fetchData when the component mounts
  // }, []); // Empty dependency array ensures this runs only once when the component mounts

  const handleViewPdf = (pdfPath) => {
  

    console.log('pdfPath  ',pdfPath)
    window.open(`${process.env.REACT_APP_API_BASE_URL}attachments/${pdfPath}`, '_blank'); 

  };

  return (
    <div className={styles.caseStudiesContainer}>
      <h1 className={styles.mainHeading}>Case Studies</h1>

      {/* {caseStudies.case_studies?.map((study) => ( */}
      {caseStudies?.map((study) => (
        <div key={study.id} className={styles.caseStudyCard}>
          <div className={styles.cardLeft}>
            {/* <h2 className={styles.cardHeading}>{study.title}</h2> */}
            <h2 className={styles.cardHeading} dangerouslySetInnerHTML={{ __html: study.title }} />
            {/* <p className={styles.cardDescription}>{study.description}</p> */}
            <p dangerouslySetInnerHTML={{ __html: study.description }} />
              <div className={styles.buttonRow}>
              <button className={styles.readMoreBtn} onClick={() => handleViewPdf(study.file)} >View
                <ArrowCircleRightOutlinedIcon className={styles.arrowIcon} />
              </button>
            
            </div>
           
          </div>
          <div className={styles.cardRight}>
            <img src={`https://cms-v2.actionfi.com/attachments/${study.image}`} alt={study.title} className={styles.caseStudyImage} />
            {/* <img src={study.image} alt={study.title} className={styles.caseStudyImage} /> */}
          </div>
        </div>
      ))}
      {hasNext === 1 && (
        <div className={styles.loadMore} onClick={loadMoreCards}>

          <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33 33" fill="none" style={{cursor:"pointer"}}>
            <circle cx="16.5" cy="16.5" r="16.5" fill="#7398C7" />
            <path d="M8 14.2864L16.5 22L25 14.2864L23.5835 13L16.5 19.4291L9.4165 13L8 14.2864Z" fill="white" />
          </svg>
        </div>

      )}
    </div>
  );
};

export default CaseStudies;
