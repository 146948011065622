import React from 'react'
import './imagecarousel.css'
const ImageCarousel = ({ images ,itemStyle,showdiv}) => {
    const distributeImages = (images, showDiv) => {
        const numArrays = showDiv ? 4 : 3; // Determine the number of arrays based on `showDiv`
        const arrays = Array.from({ length: numArrays }, () => []); // Initialize arrays dynamically
      
        images.forEach((image, index) => {
          arrays[index % numArrays].push(image); // Distribute images using modulo
        });
      
        return arrays;
      };
      
    const distributed = distributeImages(images,showdiv);
    return (
        <div>
            <div className="rc-carousel" style={{ '--tiles': 10 }}>
                <div className="rc-carousel-strip">
                    <div className="rc-carousel-box">
                        {distributed[0]?.map((image, index) => (
                            <div key={index} className="rc-carousel-item" style={itemStyle}>
                                <img className="rc-carousel-item-image" src={image} alt="dhl" />
                            </div>
                        ))}
                        {distributed[0]?.map((image, index) => (
                            <div key={index} className="rc-carousel-item" style={itemStyle}>
                                <img className="rc-carousel-item-image" src={image} alt="dhl" />
                            </div>
                        ))}
                        {distributed[0]?.map((image, index) => (
                            <div key={index} className="rc-carousel-item" style={itemStyle}>
                                <img className="rc-carousel-item-image" src={image} alt="dhl" />
                            </div>
                        ))}
                    </div>
          
                </div>
            </div>



            <div className="rc-carousel" style={{ '--tiles': 10}}>
                <div className="rc-carousel-strip reverse">
                    <div className="rc-carousel-box">
                        {distributed[1]?.map((image, index) => (
                            <div key={index} className="rc-carousel-item" style={itemStyle}>
                                <img className="rc-carousel-item-image" src={image} alt="shopify" />
                            </div>
                        ))}
                        {distributed[1]?.map((image, index) => (
                            <div key={index} className="rc-carousel-item" style={itemStyle}>
                                <img className="rc-carousel-item-image" src={image} alt="shopify" />
                            </div>
                        ))}
                        {distributed[1]?.map((image, index) => (
                            <div key={index} className="rc-carousel-item" style={itemStyle}>
                                <img className="rc-carousel-item-image" src={image} alt="shopify" />
                            </div>
                        ))}
                        {distributed[1]?.map((image, index) => (
                            <div key={index} className="rc-carousel-item" style={itemStyle}>
                                <img className="rc-carousel-item-image" src={image} alt="shopify" />
                            </div>
                        ))}
                    </div>
            
                </div>
            </div>
            <div className="rc-carousel" style={{ '--tiles': 10 }}>
                <div className="rc-carousel-strip">
                    <div className="rc-carousel-box">
                        {distributed[2]?.map((image, index) => (
                            <div key={index} className="rc-carousel-item" style={itemStyle}>
                                <img className="rc-carousel-item-image" src={image} alt="dhl" />
                            </div>
                        ))}
                        {distributed[2]?.map((image, index) => (
                            <div key={index} className="rc-carousel-item" style={itemStyle}>
                                <img className="rc-carousel-item-image" src={image} alt="dhl" />
                            </div>
                        ))}
                        {distributed[2]?.map((image, index) => (
                            <div key={index} className="rc-carousel-item" style={itemStyle}>
                                <img className="rc-carousel-item-image" src={image} alt="dhl" />
                            </div>
                        ))}
                        {distributed[2]?.map((image, index) => (
                            <div key={index} className="rc-carousel-item" style={itemStyle}>
                                <img className="rc-carousel-item-image" src={image} alt="dhl" />
                            </div>
                        ))}
                    </div>
                  
                </div>
            </div>
            {showdiv && (
                <div className="rc-carousel" style={{ '--tiles': 10 }}>
                    <div className="rc-carousel-strip reverse">
                        <div className="rc-carousel-box">
                            {distributed[3]?.map((image, index) => (
                                <div key={index} className="rc-carousel-item" style={itemStyle}>
                                    <img className="rc-carousel-item-image" src={image} alt="shopify" />
                                </div>
                            ))}
                            {distributed[3]?.map((image, index) => (
                                <div key={index} className="rc-carousel-item" style={itemStyle}>
                                    <img className="rc-carousel-item-image" src={image} alt="shopify" />
                                </div>
                            ))}
                            {distributed[3]?.map((image, index) => (
                                <div key={index} className="rc-carousel-item" style={itemStyle}>
                                    <img className="rc-carousel-item-image" src={image} alt="shopify" />
                                </div>
                            ))}
                            {distributed[3]?.map((image, index) => (
                                <div key={index} className="rc-carousel-item" style={itemStyle}>
                                    <img className="rc-carousel-item-image" src={image} alt="shopify" />
                                </div>
                            ))}
                            {distributed[3]?.map((image, index) => (
                                <div key={index} className="rc-carousel-item" style={itemStyle}>
                                    <img className="rc-carousel-item-image" src={image} alt="shopify" />
                                </div>
                            ))}
                        </div>
                     
                    </div>
                </div>
            )}
           
        </div>

    )
}

export default ImageCarousel